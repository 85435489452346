import treeify from "treeify";
import { useStore } from "@/store";
const store = useStore();

export default {
  // Declare Global function
  methods: {
    setDocumentTitle(str) {
      document.title = `${str} | website-name`;
    },

    globalNumDecFixed: function (num, dec_amount) {
      if (!(num % 1)) {
        dec_amount = 0;
      }
      return new Intl.NumberFormat("en-US", {
        minimumFractionDigits: dec_amount,
      }).format(Number.parseFloat(num).toFixed(dec_amount));
    },

    globalRandom: function (max_ran, min_ran, dec_amount = 0) {
      var new_ran = Math.random() * (max_ran - min_ran) + min_ran;
      new_ran = new_ran + Math.random() * 99999 * 0.00001;
      return this.globalNumDecFixed(new_ran, dec_amount);
    },

    millionShortNum: function (num) {
      num = parseInt(num);
      if (num >= 1000000000000000000000000) {
        return this.globalNumDecFixed(
          (num / 1000000000000000000000000).toFixed(2).replace(/\.0$/, ""),
          2
        );
      }
      if (num >= 1000000000000000000) {
        return this.globalNumDecFixed(
          (num / 1000000000000000000).toFixed(2).replace(/\.0$/, ""),
          2
        );
      }
      if (num >= 1000000000000) {
        return this.globalNumDecFixed(
          (num / 1000000000000).toFixed(2).replace(/\.0$/, ""),
          2
        );
      }
      if (num >= 1000000) {
        return this.globalNumDecFixed(
          (num / 1000000).toFixed(2).replace(/\.0$/, ""),
          2
        );
      }
      if (num < 1000000) {
        return this.globalNumDecFixed(num.toFixed(2).replace(/\.0$/, ""), 3);
      }
      return this.globalNumDecFixed(num.toFixed(2).replace(/\.0$/, ""), 2);
    },

    millionShortNumDecision: function (min, max, num) {
      var decide_val;
      num = parseInt(num);
      if (max > min) {
        decide_val = parseInt(max);
      } else {
        decide_val = parseInt(min);
      }
      if (decide_val >= 1000000000000000000000000) {
        return this.globalNumDecFixed(
          (num / 1000000000000000000000000).toFixed(2).replace(/\.0$/, ""),
          2
        );
      }
      if (decide_val >= 1000000000000000000) {
        return this.globalNumDecFixed(
          (num / 1000000000000000000).toFixed(2).replace(/\.0$/, ""),
          2
        );
      }
      if (decide_val >= 1000000000000) {
        return this.globalNumDecFixed(
          (num / 1000000000000).toFixed(2).replace(/\.0$/, ""),
          2
        );
      }
      if (decide_val >= 1000000) {
        return this.globalNumDecFixed(
          (num / 1000000).toFixed(2).replace(/\.0$/, ""),
          2
        );
      }
      if (decide_val < 1000000) {
        return this.globalNumDecFixed(num.toFixed(2).replace(/\.0$/, ""), 3);
      }
      return this.globalNumDecFixed(num.toFixed(2).replace(/\.0$/, ""), 2);
    },

    millionShortString: function (num) {
      num = parseInt(num);
      if (num >= 1000000000000000000000000) {
        return "ล้านล้านล้านล้าน";
      }
      if (num >= 1000000000000000000) {
        return "ล้านล้านล้าน";
      }
      if (num >= 1000000000000) {
        return "ล้านล้าน";
      }
      if (num >= 1000000) {
        return "ล้าน";
      }
      return "";
    },

    millionShortStringDecision: function (min, max) {
      var decide_val;
      if (max > min) {
        decide_val = parseInt(max);
      } else {
        decide_val = parseInt(min);
      }
      if (decide_val >= 1000000000000000000000000) {
        return "ล้านล้านล้านล้าน";
      }
      if (decide_val >= 1000000000000000000) {
        return "ล้านล้านล้าน";
      }
      if (decide_val >= 1000000000000) {
        return "ล้านล้าน";
      }
      if (decide_val >= 1000000) {
        return "ล้าน";
      }
      return "";
    },

    logNewData: function () {
      // console.clear();
      console.log(
        treeify.asTree(store.state.filterSideMenu.filterSideMenu, true)
      );
    },

    dateToThaiFormat: function (date) {
      date = String(date);
      var ex_date = date.split("-");
      var format_month = ex_date[1];
      var format_year = ex_date[0];
      if (format_month == 1) {
        format_month = "ม.ค";
      } else if (format_month == 2) {
        format_month = "ก.พ";
      } else if (format_month == 3) {
        format_month = "มี.ค";
      } else if (format_month == 4) {
        format_month = "เม.ย";
      } else if (format_month == 5) {
        format_month = "พ.ค";
      } else if (format_month == 6) {
        format_month = "มิ.ย";
      } else if (format_month == 7) {
        format_month = "ก.ค";
      } else if (format_month == 8) {
        format_month = "ส.ค";
      } else if (format_month == 9) {
        format_month = "ก.ย";
      } else if (format_month == 10) {
        format_month = "ต.ค";
      } else if (format_month == 11) {
        format_month = "พ.ย";
      } else if (format_month == 12) {
        format_month = "ธ.ค";
      }
      format_year = parseInt(format_year) + 543; // แปลงเป็น พ.ศ.

      // เช็คว่ามีเวลา ติดมาด้วยไหม ถ้ามีให้ split แสดงเฉพาะวันที่
      var day = String(ex_date[2]);
      var char = " ";
      if (day.includes(char) > 0) {
        day = day.split(" ");
        // console.log("->" + day[0] + " " + format_month + " " + format_year);
        return day[0] + " " + format_month + " " + format_year;
      } else {
        // console.log("->" + day + " " + format_month + " " + format_year);
        return day + " " + format_month + " " + format_year;
      }
    },
    thaiFormatToDate(date) {
      var std_date = String(date);
      var ex_date = date.split(" ");
      if (ex_date[1] == "ม.ค.") {
        ex_date[1] = "01";
      } else if (ex_date[1] == "ก.พ.") {
        ex_date[1] = "02";
      } else if (ex_date[1] == "มี.ค.") {
        ex_date[1] = "03";
      } else if (ex_date[1] == "เม.ย.") {
        ex_date[1] = "04";
      } else if (ex_date[1] == "พ.ค.") {
        ex_date[1] = "05";
      } else if (ex_date[1] == "มิ.ย.") {
        ex_date[1] = "06";
      } else if (ex_date[1] == "ก.ค.") {
        ex_date[1] = "07";
      } else if (ex_date[1] == "ส.ค.") {
        ex_date[1] = "08";
      } else if (ex_date[1] == "ก.ย.") {
        ex_date[1] = "09";
      } else if (ex_date[1] == "ต.ค.") {
        ex_date[1] = "10";
      } else if (ex_date[1] == "พ.ย.") {
        ex_date[1] = "11";
      } else if (ex_date[1] == "ธ.ค.") {
        ex_date[1] = "12";
      }
      var year = ex_date[2];
      ex_date[2] = year - 543;
      std_date = ex_date[2] + "-" + ex_date[1] + "-" + ex_date[0];
      return std_date;
    },
    getFormatedStringFromDays: function (numberOfDays) {
      var years = Math.floor(numberOfDays / 365);
      var months = Math.floor((numberOfDays % 365) / 30);
      var days = Math.floor((numberOfDays % 365) % 30);

      var yearsDisplay = years > 0 ? years + " ปี " : "";
      var monthsDisplay = months > 0 ? months + " เดือน " : "";
      var daysDisplay = days > 0 ? days + " วัน" : "";
      return yearsDisplay + monthsDisplay + daysDisplay;
    },
    setStateToLocalStorage: function () {
      if (
        this.isLocalStorage() /* function to detect if localstorage is supported*/
      ) {
        // JSON.stringify([...store.state.filterSideMenu.filterSideMenu]);
        // console.log("----------------- setStateToLocalStorage");
        // console.log(store.state.filterSideMenu.filterSideMenu);
        localStorage.setItem(
          "storedFilterSideMenu",
          JSON.stringify(store.state.filterSideMenu)
        );
        // console.log(localStorage.getItem("storedFilterSideMenu"));
      }
    },
    getLocalStorageToStoreState: function () {
      store.dispatch(
        "filterSideMenu/setStore",
        JSON.parse(localStorage.getItem("storedFilterSideMenu"))
      );
    },
    isLocalStorage: function () {
      var test = "test";
      try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
      } catch (e) {
        return false;
      }
    },
    makeId: function (length) {
      var result = "";
      var characters =
        "abcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
  },
};
