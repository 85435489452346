import { createRouter, createWebHistory } from "vue-router";
import { useAuth } from "@/hooks/useAuth";
import { useApi } from "@/hooks/useApi";

const axios = useApi();
const { getRefreshToken, setRefreshToken, setToken, clearAllToken } = useAuth();

const routes = [
  {
    path: "/documents/apis",
    name: "documents-apis",
    component: () => import("@/views/StaticApiDocument.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/",
    redirect: () => {
      // the function receives the target route as the argument
      // a relative location doesn't start with `/`
      // or { path: 'profile'}
      return { name: "login" };
    },
  },
  {
    path: "/:project",
    redirect: () => {
      // the function receives the target route as the argument
      // a relative location doesn't start with `/`
      // or { path: 'profile'}
      return { name: "map" };
    },
  },
  {
    path: "/:project/map",
    name: "map",
    component: () => import(/* webpackChunkName: "about" */ "../views/MapView.vue"),
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/:project/table",
    name: "table",
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/TableView.vue"),
    meta: {
      requireAuth: false,
    },
  },
  // {
  //   path: "/test",
  //   name: "test",
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ "../views/TestView.vue"),
  //   meta: {
  //     requireAuth: true,
  //   },
  // },
  {
    path: "/login",
    name: "login",
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/LoginView.vue"),
  },
  {
    path: "/data_source",
    // name: "data-source-manage",
    children: [
      {
        path: "manage",
        name: "data-source-manage",
        component: () => import(/* webpackChunkName: "about" */ "../components/pages/manage/data_source/ManageDataSource.vue"),
        meta: {
          admin: true,
          requireAuth: true,
        },
      },
      {
        path: "create",
        name: "create-data-source",
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../components/pages/manage/data_source/CreateDataSource.vue"),
        meta: {
          admin: true,
          requireAuth: true,
        },
      },
      {
        path: "edit/:id",
        name: "edit-data-source",
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../components/pages/manage/data_source/EditDataSource.vue"),
        meta: {
          admin: true,
          requireAuth: true,
        },
      },
    ],
  },
  {
    path: "/project",
    // which is lazy-loaded when the route is visited.
    children: [
      {
        path: "manage",
        name: "project-manage",
        component: () => import(/* webpackChunkName: "about" */ "../components/pages/manage/project/ManageProject.vue"),
        meta: {
          admin: true,
          requireAuth: true,
        },
      },
      {
        path: "create",
        name: "create-project",
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../components/pages/manage/project/CreateProject.vue"),
        meta: {
          admin: true,
          requireAuth: true,
        },
      },
      {
        path: "edit/:id",
        name: "edit-project",
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../components/pages/manage/project/EditProject.vue"),
        meta: {
          admin: true,
          requireAuth: true,
        },
      },
      {
        path: "mange-user/:id",
        name: "user-project",
        component: () => import("@/components/pages/manage/project/UserMangeProject.vue"),
        meta: {
          admin: true,
          requireAuth: true,
        },
      },
      {
        path: "mange-role/:id",
        name: "role-project",
        component: () => import("@/components/pages/manage/project/RoleMange.vue"),
        meta: {
          admin: true,
          requireAuth: true,
        },
      },
    ],
  },
  {
    path: "/*",
    redirect: () => ({ name: "not-found" }),
  },
  {
    path: "/404",
    name: "not-found",
    component: () => import("@/views/404View.vue"),
  },
  {
    path: "/no-acess",
    name: "no-acess",
    component: () => import("@/views/NoAcess.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from) => {
  const refresh_token = getRefreshToken();
  if (!refresh_token) {
    if (to.meta.requireAuth) return { name: "login", query: { redirect_path: from.path } };
    return;
  }
  const res = await axios.post("/api/user/token_refresh", { TOKEN_REFRESH: refresh_token }).catch((err) => {
    clearAllToken();
    return err.response;
  });

  if (res.status === 200 && res.data) {
    const data = res.data;
    setToken(data.TOKEN);
    setRefreshToken(data.TOKEN_REFRESH);
  } else {
    clearAllToken();

    if (to.meta.requireAuth) return { name: "login", query: { redirect_path: from.path } };
  }
});

export default router;
