const INIT_FILTER_VALUE = {
  tableLoading: false,
  tableKeyUpdate: 0,

  dateContractStart: null,
  equipmentValue: [],
  organizeValue: null,
  minAmountValue: null,
  maxAmountValue: null,
  provinceList: [],
  provinceValue: [],
};

const state = () => {
  return {
    ...INIT_FILTER_VALUE,
  };
};

// getters
const getters = {
  getTableLoading: (state) => state.tableLoading,
};

// actions
const actions = {};

// mutations
const mutations = {
  clearStore(state) {
    state = {
      ...INIT_FILTER_VALUE,
    };
    Object.assign(state, state);
  },
  setTableLoading(state, data) {
    state.tableLoading = data;
  },
  setTableKeyUpdate(state) {
    state.tableKeyUpdate++;
    // console.log(state.tableKeyUpdate);
  },
  setDateContractStart(state, data) {
    state.dateContractStart = data;
  },
  setEquipmentValue(state, data) {
    state.equipmentValue = data;
  },
  setOrganizeValue(state, data) {
    state.organizeValue = data;
  },
  setMinAmountValue(state, data) {
    state.minAmountValue = data;
  },
  setMaxAmountValue(state, data) {
    state.maxAmountValue = data;
  },
  setProvinceValue(state, data) {
    state.provinceValue = data;
  },
  setProvinceList(state, data) {
    state.provinceList = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
