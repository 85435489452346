import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/styles/app.scss";

import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_BASE_API;
axios.defaults.headers.common["Content-Type"] = "application/json; charset=utf-8";
// axios.defaults.headers.common['Cache-Control'] = 'max-age=3600';
// ---------------- Tailwind elements -------------------------
import "tw-elements";

// ---------------- Vue select -------------------------
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

// ---------------- Infinite pre-loader -------------------------
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css"; //required if you're not going to override default slots

// ---------------- Fontawesome -------------------
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faCircle,
  faCircleUser,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faCaretDown,
  faCalendarDays,
  faSpinner,
  faReceipt,
  faCartShopping,
  faLandmark,
  faForward,
  faForwardStep,
  faMap,
  faMapLocationDot,
  faRightFromBracket,
  faUser,
  faPenToSquare,
  faPowerOff,
  faTrash,
  faRocket,
  faDatabase,
  faLink,
  faLock,
  faEarthAsia,
  faArrowUpFromBracket,
  faXmarkCircle,
  faPlus,
  faKey,
  faEye,
  faEyeSlash,
  faFloppyDisk,
  faCheck,
  faX,
  faChevronDown,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
library.add(
  faCircle,
  faCircleUser,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCaretDown,
  faCalendarDays,
  faSpinner,
  faReceipt,
  faCartShopping,
  faLandmark,
  faForward,
  faForwardStep,
  faMap,
  faMapLocationDot,
  faRightFromBracket,
  faUser,
  faPenToSquare,
  faPowerOff,
  faTrash,
  faRocket,
  faDatabase,
  faLink,
  faLock,
  faEarthAsia,
  faArrowUpFromBracket,
  faXmarkCircle,
  faPlus,
  faKey,
  faEye,
  faEyeSlash,
  faFloppyDisk,
  faCheck,
  faX,
  faExclamationCircle
);

// ---------------- Popper -------------------
import Popper from "vue3-popper";
import "./assets/styles/vue_popper.css"; // Magic happens here

// ---------------- Croppie -------------------
// import VueCroppie from 'vue-croppie';
// import 'croppie/croppie.css' // import the croppie css manually

// -------------- Gtag --------------------
import VueGtag from "vue-gtag";

const app = createApp(App);
// app.prototype.axios = Axios;

app
  .use(router)
  .use(store)
  .use(
    VueGtag,
    {
      config: { id: process.env.VUE_APP_G_TAG },
    },
    router
  )
  // .use(VueCroppie)
  .component("Popper", Popper)
  .component("v-select", vSelect)
  .component("infinite-loading", InfiniteLoading)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
