const INIT_DATA_SOURCE_VALUE = {
  dataSource: [
    {
      ID: "c64w3s4dwf6madu3wwkw8ewy",
      NAME: "e-GP",
      DESCRIPTION:
        "ข้อมูลที่ได้จากระบบการจัดซื้อจัดจ้างภาครัฐด้วยระบบอิเล็คทรอนิกส์ (e-GP)",
      COLLECTION_NAME: "TEST_COLLECTION_NAME",
      STATUS: "ACTIVE",
    },
    {
      ID: "px879h7q4822hy3jda99nb29",
      NAME: "e-GP2",
      DESCRIPTION: "ข้อมูลงบประมาณจาก GFMIS",
      COLLECTION_NAME: "TEST_COLLECTION_NAME 2",
      STATUS: "INACTIVE",
    },
  ],
};

const state = () => {
  return {
    ...INIT_DATA_SOURCE_VALUE,
  };
};

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  clearStore(state) {
    state = {
      ...INIT_DATA_SOURCE_VALUE,
    };
    Object.assign(state, state);
  },
  createDataSource(state, data) {
    setTimeout(() => {
      state.dataSource.push(data);
      return true;
    }, 500);
  },
  enableDataSource(state, id) {
    setTimeout(() => {
      var select_data_source = state.dataSource.findIndex((x) => x.ID === id);
      if (state.dataSource[select_data_source].STATUS == "INACTIVE") {
        state.dataSource[select_data_source].STATUS = "ACTIVE";
      } else {
        state.dataSource[select_data_source].STATUS = "INACTIVE";
      }
    }, 250);
  },
  removeDataSource(state, id) {
    setTimeout(() => {
      var select_data_source = state.dataSource.findIndex((x) => x.ID === id);
      state.dataSource.splice(select_data_source, 1);
    }, 250);
  },
  editDataSource(state, payload) {
    setTimeout(() => {
      var select_data_source = state.dataSource.findIndex((x) => x.ID === payload.id);
      state.dataSource[select_data_source] = payload.value;
    }, 250);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
