import axios from "axios";

import { useAuth } from "./useAuth";
import router from "@/router/index";
import { useRoute } from "vue-router";

let api;

let refreshRequest = null;

const { getToken, getRefreshToken, setRefreshToken, setToken, clearAllToken } = useAuth();
export function createApi() {
  // Here we set the base URL for all requests made to the api
  api = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
  });

  // We set an interceptor for each request to
  // include Bearer token to the request if user is logged in
  api.interceptors.request.use(
    (config) => {
      const token = getToken();

      // if (userSession.isLoggedIn) {
      config.headers = {
        ...config.headers,
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      };
      // }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response.status === 401) {
        if (!refreshRequest) {
          refreshRequest = sendRefreshRequest();
        }

        await refreshRequest;

        const access_token = getToken();
        if (access_token) {
          return api(error.config);
        }
      }

      return Promise.reject(error);
    }
  );

  return api;
}

function sendRefreshRequest(route) {
  const refresh_token = getRefreshToken();
  return api
    .post("/api/user/token_refresh", { TOKEN_REFRESH: refresh_token })
    .then(function (response) {
      const { data } = response;
      setToken(data.TOKEN);
      setRefreshToken(data.TOKEN_REFRESH);

      const access_token = getToken();
      api.defaults.headers.common["Authorization"] = access_token;
      refreshRequest = null;
    })
    .catch(function () {
      clearAllToken();
      router.replace({ name: "login", params: { redirect_path: route.path } });
      // also you can do something...
    });
}

export function useApi() {
  const route = useRoute();
  if (!api) {
    createApi(route);
  }
  return api;
}
