import { Buffer } from "buffer";

export function useAuth() {
  const getToken = () => {
    return window.localStorage.getItem("token");
  };

  const getRefreshToken = () => {
    return window.localStorage.getItem("refresh-token");
  };

  const setToken = (token) => {
    window.localStorage.setItem("token", token);
  };

  const setRefreshToken = (token) => {
    window.localStorage.setItem("refresh-token", token);
  };

  const clearAllToken = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("refresh-token");
  };

  const getPayload = (token) => {
    const jwt = token.split(".");
    const encodePayload = jwt[1];
    const decodePayload = Buffer.from(encodePayload, "base64").toString("utf8");
    const jsonPayload = JSON.parse(decodePayload);
    return jsonPayload.userInfo;
  };

  const getUser = () => {
    const token = getToken();
    if (!token) return null;
    const user = getPayload(token);
    return user;
  };

  const exportPermission = () => {
    if (!getUser()) return false;
    const { PERMISSION: permission } = getUser();
    for (let i = 0; i < permission.length; i++) {
      const element = permission[i];
      if (element === "นำออกข้อมูลการจัดซื้อ") return true;
      if (element === "ALL PERMISSION") return true;
    }
    return false;
  };

  const showBuyPricePermission = () => {
    if (!getUser()) return false;
    const { PERMISSION: permission } = getUser();
    for (let i = 0; i < permission.length; i++) {
      const element = permission[i];
      if (element === "แสดงมูลค่าการจัดซื้อ") return true;
      if (element === "ALL PERMISSION") return true;
    }
    return false;
  };

  const countryPermission = () => {
    if (!getUser()) return false;
    const { PERMISSION: permission } = getUser();
    for (let i = 0; i < permission.length; i++) {
      const element = permission[i];
      if (element === "แสดงข้อมูลทั้งประเทศ") return true;
      if (element === "ALL PERMISSION") return true;
    }
    return false;
  };

  const districtPermission = () => {
    if (!getUser()) return false;
    const { PERMISSION: permission } = getUser();
    for (let i = 0; i < permission.length; i++) {
      const element = permission[i];
      if (element === "แสดงข้อมูลเขต") return true;
      // if (element === "ALL PERMISSION") return true;
    }
    return false;
  };
  const provincePermission = () => {
    if (!getUser()) return false;
    const { PERMISSION: permission } = getUser();
    for (let i = 0; i < permission.length; i++) {
      const element = permission[i];
      if (element === "แสดงข้อมูลจังหวัด") return true;
      // if (element === "ALL PERMISSION") return true;
    }
    return false;
  };

  const isAdmin = () => {
    if (!getUser()) return false;
    const { PERMISSION: permission } = getUser();
    for (let i = 0; i < permission.length; i++) {
      const element = permission[i];
      if (element === "ALL PERMISSION") return true;
    }
    return false;
  };

  return {
    getToken,
    getRefreshToken,
    setToken,
    setRefreshToken,
    clearAllToken,
    getUser,
    exportPermission,
    showBuyPricePermission,
    countryPermission,
    districtPermission,
    provincePermission,
    isAdmin,
  };
}
