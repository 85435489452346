<template>
  <Multiselect v-bind="props">
    <template v-for="(_, slot) of $slots" v-slot:[slot]="scope" :key="slot">
      <slot :name="slot" v-bind="scope" />
    </template>
    <template #afterList>
      <div :id="`${props.id}-scrollto`"></div>
    </template>
  </Multiselect>
</template>

<script setup>
import { onMounted, onUnmounted } from "vue";
import { Multiselect, multiselectMixin } from "vue-multiselect";
// import MultiselectTag from "./MultiselectTag.vue";
const props = defineProps({
  loading: Boolean,
  ...multiselectMixin.props,
});
const emits = defineEmits(["fetchOption"]);

let optionElement;
let prevScroll;

const handleScroll = (e) => {
  if (prevScroll < e.target.scrollHeight && e.target.scrollHeight - e.target.scrollTop <= 300) {
    emits("fetchOption");
  }
  prevScroll = e.target.scrollTop;
};

const getElement = () => {
  optionElement = document.getElementById(`${props.id}-scrollto`).parentElement.parentElement;
  optionElement.addEventListener("scroll", handleScroll);
};

onMounted(() => {
  getElement();
});

onUnmounted(() => {
  optionElement.removeEventListener("scroll", handleScroll);
});
</script>
