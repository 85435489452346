const INIT_PROJECT_DATA_VALUE = {
  loading: true,
  _id: "",
  name: "HDX Thailand",
  description: "โครงการแสดงข้อมูลการจัดซื้อจัดจ้าง วัสดุ/อุปกรณ์ ทางการแพทย์",
  url: "TEST",
  logo: "TEST",
  permissions: "PUBLIC",
  datasources: [
    // {
    //   ID: "c64w3s4dwf6madu3wwkw8ewy",
    // },
  ],
  status: "ACTIVE",
};

const state = () => {
  return {
    ...INIT_PROJECT_DATA_VALUE,
  };
};

// getters
const getters = {
  getLogo: (state) => state.logo,
  getName: (state) => state.name,
  projectLoading: (state) => state.loading,
};

// actions
const actions = {
  setProject({ commit }, data) {
    commit("setProject", data);
  },
  setLoading({ commit }, data) {
    commit("setLoading", data);
  },
};

// mutations
const mutations = {
  setLoading(state, data) {
    state.loading = data;
  },
  setProject(state, data) {
    Object.assign(state, data);
  },
  clearStore(state) {
    state = {
      ...INIT_PROJECT_DATA_VALUE,
    };
    Object.assign(state, state);
  },
  createDataSource(state, data) {
    setTimeout(() => {
      state.project.push(data);
      return true;
    }, 500);
  },
  enableDataSource(state, id) {
    setTimeout(() => {
      var select_project_data = state.project.findIndex((x) => x.ID === id);
      if (state.project[select_project_data].STATUS == "INACTIVE") {
        state.project[select_project_data].STATUS = "ACTIVE";
      } else {
        state.project[select_project_data].STATUS = "INACTIVE";
      }
    }, 250);
  },
  removeDataSource(state, id) {
    setTimeout(() => {
      var select_project_data = state.project.findIndex((x) => x.ID === id);
      state.project.splice(select_project_data, 1);
    }, 250);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
