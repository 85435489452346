<template>
  <!-- <div> -->
  <!-- <div v-if="layout == 'blank'">
      <RouterView v-slot="{ Component }">
        <Transition name="page-transition" mode="out-in">
          <component :is="Component" />
        </Transition>
      </RouterView>
    </div> -->
  <div>
    <HeaderLayout v-if="layout !== 'blank'" />
    <div :class="[layout !== 'blank' && 'flex w-full mt-16']">
      <template v-if="!store.getters['filterSideMenu/getLoadingProject']">
        <FilterSidebar v-if="layout === 'dashboard'" class="flex" />
      </template>
      <AdminNavbar v-if="layout === 'admin' || layout === 'user-role-project'" :user-role-project="layout === 'user-role-project'" class="flex" />
      <!-- <FilterLayout v-if="!$route.meta.admin" class="flex" />
        <FilterLayoutAdmin v-else class="flex" /> -->
      <RouterView v-slot="{ Component }">
        <Transition name="page-transition" mode="out-in">
            <component :is="Component" />
        </Transition>
      </RouterView>
    </div>
  </div>
  <!-- </div> -->
</template>

<script setup>
import { ref, provide } from "vue";
import { useStore } from "vuex";
// import { useToken } from "./hooks/useToken";
// import { useApi } from "./hooks/useApi";

// const axios = useApi();
const store = useStore();

const layout = ref("blank");
const projectTitle = ref("");

const updateValue = (layoutval) => {
  // blank, admin, dashboard
  layout.value = layoutval;
};

const updateProjectTitle = (val) => {
  projectTitle.value = val;
};

provide("nav", { layout, updateValue, updateProjectTitle, projectTitle });

// const { getRefreshToken, setToken, setRefreshToken, clearAllToken } = useToken();

// let refreshTokenInterval;

// onMounted(async () => {
//   const refreshToken = getRefreshToken();
//   if (refreshToken) {
//     const data = { TOKEN_REFRESH: refreshToken };
//     const res = await axios.post(`/api/user/token_refresh`, data);
//     if (res.status === 200 && res.data) {
//       const data = res.data;
//       setToken(data.TOKEN);
//       setRefreshToken(data.TOKEN_REFRESH);
//     } else {
//       clearAllToken();
//     }
//     refreshTokenInterval = setInterval(async () => {
//       console.log("refresh");
//       const refreshToken = getRefreshToken();
//       const data = { TOKEN_REFRESH: refreshToken };
//       const res = await axios.post(`/api/user/token_refresh`, data);
//       if (res.status === 200 && res.data) {
//         const data = res.data;
//         setToken(data.TOKEN);
//         setRefreshToken(data.TOKEN_REFRESH);
//       } else {
//         clearAllToken();
//         clearInterval(refreshTokenInterval);
//       }
//     }, 300000);
//   }
// });
// onUnmounted(() => {
//   if (refreshTokenInterval) clearInterval(refreshTokenInterval);
// });
</script>

<script>
import HeaderLayout from "@/components/layouts/HeaderLayout.vue";
import FilterLayout from "@/components/sidebar/FilterLayout.vue";
import FilterLayoutAdmin from "@/components/sidebar/FilterLayoutAdmin.vue";
import FilterSidebar from "./components/sidebar/FilterSidebar.vue";
import AdminNavbar from "./components/sidebar/AdminNavbar.vue";

// import { useStore } from "@/store";
// const store = useStore();

import Mixins from "@/Mixins";

export default {
  mixins: [Mixins],
  components: {
    HeaderLayout,
    FilterLayout,
    FilterLayoutAdmin,
    FilterSidebar,
    AdminNavbar,
  },
  data() {
    return {
      loginSuccess: false,
    };
  },
  created() {
    if (Mixins.methods.isLocalStorage) {
      Mixins.methods.getLocalStorageToStoreState();

      // console.log(" -> On App mounted <-");
      // console.log(store.state.filterSideMenu.filterSideMenu);
      // console.log(" -> On App mounted <-");
      // console.log(store.state.dataSource);

      // เช็คเพื่อ Reset Local storage
      var hours = 10; // Reset when storage is more than 10 minutes
      var now = new Date().getTime();
      var setupTime = localStorage.getItem("setupTime");
      if (setupTime == null) {
        localStorage.setItem("setupTime", now);
      } else {
        if (now - setupTime >= hours * 60 * 1000) {
          localStorage.removeItem("setupTime");
          localStorage.setItem("setupTime", now);
        } else {
          localStorage.setItem("setupTime", now); // กรณีถ้ายังไม่เกิน 10 นาที ก็ให้เริ่มนับ 10 นาทีใหม่
        }
      }
      // .end เช็คเพื่อ Reset Local storage
    }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
#app {
  font-family: "Kanit", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 20px;
  height: 70px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.page-transition-enter-active,
.page-transition-leave-active {
  transition: 600ms ease all;
}

.page-transition-enter-from,
.page-transition-leave-to {
  opacity: 0;
}
</style>
