const state = () => {
  return {
    ID: "",
    NAME: "",
    ORG_NAME: "",
    POSITION: "",
    PROJECT: [],
    PROVINCE_CODE: "",
    PROVINCE_NAME: "",
    ROLE: [],
    STATUS: "",
    SURNAME: "",
    TITLE: "",
  };
};

const getters = {};
const actions = {
  setUserData({ commit }, data) {
    commit("setUserData", data);
  },
};
const mutations = {
  setUserData(state, data) {
    // Object.assign(state, data);
    state.ID = data.ID;
    state.NAME = data.NAME;
    state.ORG_NAME = data.ORG_NAME;
    state.POSITION = data.POSITION;
    state.PROJECT = data.PROJECT;
    state.PROVINCE_CODE = data.PROVINCE_CODE;
    state.PROVINCE_NAME = data.PROVINCE_NAME;
    state.ROLE = data.ROLE;
    state.STATUS = data.STATUS;
    state.SURNAME = data.SURNAME;
    state.TITLE = data.TITLE;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
