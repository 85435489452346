const INIT_VALUE = {
  data_source: "แหล่งข้อมูล",
  display_view: "มุมมองการแสดงผล",
  display_data: "ข้อมูลที่แสดงผล",
  display_level: "ระดับการแสดงผล",
  filter_time: "กรองเวลา",
  filter_year_type: "รูปแบบปี",
  filter_year_purchase: "ปีที่จัดซื้อ",
  filter_province: "จังหวัด",
  filter_amphur: "อำเภอ",
  filter_tambol: "ตำบล",
  filter_materials_or_equipment: "พัสดุ/ครุภัณฑ์",
  filter_materials_supplies: "พัสดุจัดหา",
  filter_tool_or_equipment_name: "ชื่อเครื่องมือ/อุปกรณ์",
};

const state = () => {
  return {
    ...INIT_VALUE,
  };
};

const getters = {};

const actions = {
  setNewLabel({ commit }, data) {
    commit("setNewLabel", data);
  },
};

const mutations = {
  setNewLabel(state, data) {
    Object.assign(state, data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
