<template>
  <div>
    <div class="relative block lg:hidden left-0" style="min-width: 30px; z-index: 9 !important"></div>

    <div
      class="absolute lg:relative transition"
      style="z-index: 998"
      :class="{
        'w-80': $store.state.filterSideMenu.isOpenSidebar == true,
        'w-7': $store.state.filterSideMenu.isOpenSidebar == false,
      }"
    >
      <span @click="hideSideMenu" class="absolute drop-shadow-md hover:drop-shadow-xl rounded-full bg-white p-1 mt-5 cursor-pointer z-[999] top-1 -right-5">
        <template v-if="$store.state.filterSideMenu.isOpenSidebar">
          <chevrons-left-icon size="2x" class=""></chevrons-left-icon>
        </template>
        <template v-else>
          <chevrons-right-icon size="2x" class="ml-0.5"></chevrons-right-icon>
        </template>
      </span>

      <aside
        :class="{
          'w-80': $store.state.filterSideMenu.isOpenSidebar == true,
          'w-7': $store.state.filterSideMenu.isOpenSidebar == false,
        }"
        class="mt-1 bg-dark-blue-custom h-full fixed pr-4"
        aria-label="Sidebar"
      >
        <div
          class="py-4 px-5 text-left text-white dark:bg-gray-800 overflow-y-auto scrollbar-custom scrollbar-custom-light"
          style="height: calc(100vh - 135px)"
          :class="{
            block: $store.state.filterSideMenu.isOpenSidebar == true,
            hidden: $store.state.filterSideMenu.isOpenSidebar == false,
          }"
        >
          <div class="flex justify-center">
            <div class="rounded-sm w-full text-gray-900 gap-2 mt-5 grid">
              <router-link :to="'/data_source/manage'" custom v-slot="{ isActive, href, navigate }">
                <a
                  :href="{ href }"
                  class="block px-6 py-2 w-full rounded-lg side-menu-list-custom"
                  :class="{ active: isActive || $route.matched.some(({ route }) => $route.path.includes('/data_source/')) }"
                  @click="navigate"
                >
                  <font-awesome-icon :icon="['fas', 'fa-database']"></font-awesome-icon> &nbsp;ชุดข้อมูล
                </a>
              </router-link>
              <router-link :to="'/project/manage'" custom v-slot="{ isActive, href, navigate }">
                <a
                  :href="{ href }"
                  class="block px-6 py-2 w-full rounded-lg side-menu-list-custom"
                  :class="{ active: isActive || $route.matched.some(({ route }) => $route.path.includes('/project/')) }"
                  @click="navigate"
                >
                  <font-awesome-icon :icon="['fas', 'fa-rocket']"></font-awesome-icon> &nbsp;โครงการ
                </a>
              </router-link>
            </div>
          </div>
        </div>
      </aside>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

import Mixins from "@/Mixins";
import { ChevronsRightIcon, ChevronsLeftIcon } from "@zhuowenli/vue-feather-icons";

import { useStore } from "@/store";
const store = useStore();

export default {
  components: {
    ChevronsRightIcon,
    ChevronsLeftIcon,
  },
  mixins: [Mixins],
  methods: {
    hideSideMenu: function () {
      store.dispatch("filterSideMenu/hideSideMenuClick");
    },

    setSidebarOpen: function () {
      var width = $(window).width();
      // var height = $(window).height();
      if (width >= 1199) {
        store.commit("filterSideMenu/setIsOpenSidebar", true);
      } else {
        store.commit("filterSideMenu/setIsOpenSidebar", false);
      }
    },
  },
  mounted() {
    this.setSidebarOpen();
    var vm = this;
    // var vm = this;
    $(window).resize(function () {
      vm.setSidebarOpen();
    });
  },
};
</script>

<style scoped>
.display-btn {
  border: 3px solid transparent;
  opacity: 0.5;
}

.display-btn:hover {
  opacity: 1;
}

.display-btn.active {
  opacity: 1;
  border: 3px solid var(--orange-color);
}

.btn-group-custom {
  opacity: 0.5;
  color: var(--dark-blue-color);
}

.btn-group-custom:hover {
  opacity: 1;
}

.btn-group-custom.active {
  opacity: 1;
  color: var(--orange-color);
}

.collapse {
  visibility: visible;
}

.multiselect__element .multiselect__option {
  white-space: nowrap !important;
  max-width: calc(100% - 100px) !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.multiselect__clear {
  position: absolute;
  right: 41px;
  height: 40px;
  width: 40px;
  display: block;
  cursor: pointer;
  z-index: 2;
}

.multiselect__clear:after,
.multiselect__clear:before {
  content: "";
  display: block;
  position: absolute;
  width: 3px;
  height: 16px;
  background: #aaa;
  top: 12px;
  right: 4px;
  cursor: pointer;
}

.multiselect__clear:before {
  transform: rotate(45deg);
}

.multiselect__clear:after {
  transform: rotate(-45deg);
}

.side-menu-list-custom {
  color: var(--orange-color);
  transition: all 0.3s ease-in-out;
}

.side-menu-list-custom:hover {
  background-color: rgba(255, 255, 255, 0.5);
  color: var(--dark-blue-color);
}

.side-menu-list-custom.active {
  background-color: rgba(255, 255, 255, 0.9);
  color: var(--dark-blue-color);
}
</style>
