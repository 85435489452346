<template>
  <nav
    class="bg-white px-2 sm:px-4 py-3.5 dark:bg-gray-900 fixed w-full top-0 left-0 border-b border-gray-200 dark:border-gray-600"
    style="z-index: 1000"
  >
    <div class="grid grid-cols-3">
      <div class="md:hidden"></div>
      <router-link
        :to="{
          name: !isAdmin() ? 'map' : 'project-manage',
          ...(!isAdmin()
            ? { params: { project: !route.params.project ? (user && user.PROJECT_URL ? user.PROJECT_URL : 'hdx') : route.params.project } }
            : {}),
        }"
        class="place-content-center md:place-content-start"
      >
        <a class="flex justify-center md:justify-start items-center" v-if="!projectLoading">
          <img :src="logoComputed" class="md:mr-2 h-8 md:h-6" :alt="`${nameComputed} Logo`" />
          <span class="hidden md:block self-center text-2xl font-semibold whitespace-nowrap text-dark-blue-custom dark:text-white">
            {{ nameComputed }}
          </span>
        </a>
        <div v-else class="flex justify-center items-center">
          <font-awesome-icon :icon="['fas', 'fa-spinner']" class="animate-spin text-orange-500 font-lg fa-2x z-[99]" />
        </div>
      </router-link>

      <!-- Right elements -->
      <template v-if="!user">
        <div class="col-start-3 place-content-end">
          <CustomButton color="primary" @click="handleLogin">ลงชื่อเข้าใช้</CustomButton>
        </div>
      </template>
      <template v-else>
        <div class="flex items-center relative col-start-3 place-content-end">
          <div class="dropdown relative" data-te-dropdown-ref>
            <a
              class="dropdown-toggle flex items-center hidden-arrow"
              href="#"
              type="button"
              id="dropdownMenuButton2"
              data-te-dropdown-toggle-ref
              aria-expanded="false"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              <font-awesome-icon :icon="['fas', 'fa-circle-user']" class="mr-2 mt-1 text-3xl" />
              &nbsp;
              <p class="font-semibold hidden md:block">{{ fullname }}</p>
              &nbsp;&nbsp; <font-awesome-icon :icon="['fas', 'fa-chevron-down']" class="mr-2 mt-1 text-lg text-orange-custom hidden md:block" />
            </a>
            <ul
              class="dropdown-menu min-w-max absolute hidden bg-white text-base z-[1000] float-left py-2 list-none text-left shadow-lg mt-3 bg-clip-padding left-auto right-0 top-5 border-t-2 border-orange-500 rounded [&[data-te-dropdown-show]]:block"
              aria-labelledby="dropdownMenuButton2"
              data-te-dropdown-menu-ref
            >
              <li class="border-b-2 border-gray-100">
                <!-- :to="() => (user.isAdmin() ? { name: 'data-source-manage' } : {})" -->
                <a
                  class="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                  @click="
                    () => {
                      if (isAdmin()) {
                        $router.push({ name: 'data-source-manage', replace: true });
                      }
                    }
                  "
                  data-te-dropdown-item-ref
                >
                  <font-awesome-icon :icon="['fas', 'fa-user']" class="mr-2 mt-1 text-sm text-gray-400" />
                  <span class="text-dark-blue-custom font-medium">{{ fullname }}</span
                  ><br />
                  <span class="ml-5 text-gray-400">{{ user && user.POSITION ? user.POSITION : "-" }}</span>
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100 text-red-500"
                  href="#"
                  @click="logOutAction"
                  data-te-dropdown-item-ref
                >
                  <font-awesome-icon :icon="['fas', 'fa-right-from-bracket']" class="mr-2 mt-1 text-sm" />
                  <span class="">ออกจากระบบ</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </template>
      <!-- Right elements -->
    </div>
  </nav>
</template>

<script setup>
import { computed } from "vue";
import { useSweetAlert } from "@/hooks/useSweetAlert";
import { useRouter, useRoute } from "vue-router";
import { useAuth } from "@/hooks/useAuth";
import { useStore } from "vuex";

// ** Component
import CustomButton from "../base/button/custom-button.vue";

const router = useRouter();
const route = useRoute();
const Swal = useSweetAlert();
const store = useStore();
const { clearAllToken, getUser, isAdmin } = useAuth();

const user = computed(() => getUser());
const fullname = computed(() => {
  if (user.value.ROLE[0] === "Admin") return "ผู้ดูแลระบบ";
  return `${user.value && user.value.NAME ? user.value.NAME : "-"}  ${user.value && user.value.SURNAME ? user.value.SURNAME : "-"}`;
});

const projectLoading = computed(() => store.getters["projectData/projectLoading"]);

const logoComputed = computed(() => {
  const imgsrc = store.getters["projectData/getLogo"];
  if (imgsrc !== "TEST") return imgsrc;
  return require("@/assets/images/icon/stethoscope.png");
});

const nameComputed = computed(() => {
  const name = store.getters["projectData/getName"];
  if (isAdmin()) return "HDX Admin";
  if (name) return name;
  return "HDX Thailand";
});

const logOutAction = function () {
  Swal.fire({
    // title: `<font-awesome-icon :icon="['fas', 'fa-right-from-bracket']" class="mr-2 mt-1 text-sm" />&nbsp; ออกจากระบบ`,
    title: `ออกจากระบบ`,
    text: "คุณต้องการออกจากระบบจริงหรือไม่ ?",
    // showDenyButton: true,
    showCancelButton: true,
    confirmButtonText: "ออกจากระบบ",
    cancelButtonText: `ยกเลิก`,
    confirmButtonColor: "rgb(239 68 68)",
    // cancelButtonColor: '#d33',
  }).then((result) => {
    if (result.isConfirmed) {
      clearAllToken();
      router.push({ name: "login", replace: true });
    }
  });
};

const handleLogin = () => {
  router.push({ name: "login" });
};
</script>
