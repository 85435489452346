<template>
  <SidebarLayout>
    <template #before-body>
      <div class="display-level-capsule-container absolute mt-5 ml-12 float-left pr-4" :class="{ active: $store.state.filterSideMenu.isOpenSidebar }">
        <span
          class="display-level-capsule card pt-0 bg-white"
          style="padding-top: 0.5rem; border-radius: 999px; z-index: 99"
          v-if="$route.name == 'map' && $store.state.filterSideMenu.filterSideMenu.displayLevel == 'PROVINCE'"
        >
          <span v-if="$store.state.filterSideMenu.filterSideMenu.provinceSelect == null">
            {{ $store.state.sideMenuLabel.filter_province }} <span class="text-orange-custom mx-1">ทั้งประเทศ</span>
          </span>
          <span v-if="$store.state.filterSideMenu.filterSideMenu.provinceSelect != null">
            {{ $store.state.sideMenuLabel.filter_province }}
            <span class="text-orange-custom mx-1">{{ $store.state.filterSideMenu.filterSideMenu.provinceSelect.name }}</span>
          </span>
          <span v-if="$store.state.filterSideMenu.filterSideMenu.amphurSelect != null" class="ml-2">
            {{ $store.state.sideMenuLabel.filter_amphur }}
            <span class="text-orange-custom mx-1">{{ $store.state.filterSideMenu.filterSideMenu.amphurSelect.name }}</span>
          </span>
          <span v-if="$store.state.filterSideMenu.filterSideMenu.tambolSelect != null" class="ml-2">
            {{ $store.state.sideMenuLabel.filter_tambol }}
            <span class="text-orange-custom mx-1">{{ $store.state.filterSideMenu.filterSideMenu.tambolSelect.name }}</span>
          </span>
        </span>
      </div>
      <div class="display-level-capsule-container absolute mt-5 ml-12 float-left pr-4" :class="{ active: $store.state.filterSideMenu.isOpenSidebar }">
        <span
          class="display-level-capsule card pt-0 bg-white"
          style="padding-top: 0.5rem; border-radius: 999px; z-index: 99"
          v-if="$route.name == 'map' && $store.state.filterSideMenu.filterSideMenu.displayLevel == 'AREA'"
        >
          <span v-if="$store.state.filterSideMenu.filterSideMenu.healthArea == null">
            เขตสุขภาพ <span class="text-orange-custom mx-1">ทุกเขต</span>
          </span>
          <span v-if="$store.state.filterSideMenu.filterSideMenu.healthArea != null" class="ml-2">
            เขตสุขภาพ
            <span class="text-orange-custom mx-1"> {{ $store.state.filterSideMenu.filterSideMenu.healthArea }}</span>
          </span>
          <!-- <span
              v-if="
                $store.state.filterSideMenu.filterSideMenu.provinceSelect == null
              "
            >
              จังหวัด : <span class="text-orange-custom mx-1">ทั้งเขต</span>
            </span> -->
          <span v-if="$store.state.filterSideMenu.filterSideMenu.provinceSelect != null" class="ml-2">
            {{ $store.state.sideMenuLabel.filter_province }}
            <span class="text-orange-custom mx-1">{{ $store.state.filterSideMenu.filterSideMenu.provinceSelect.name }}</span>
          </span>
          <span v-if="$store.state.filterSideMenu.filterSideMenu.amphurSelect != null" class="ml-2">
            {{ $store.state.sideMenuLabel.filter_amphur }}
            <span class="text-orange-custom mx-1">{{ $store.state.filterSideMenu.filterSideMenu.amphurSelect.name }}</span>
          </span>
          <span v-if="$store.state.filterSideMenu.filterSideMenu.tambolSelect != null" class="ml-2">
            {{ $store.state.sideMenuLabel.filter_tambol }}
            <span class="text-orange-custom mx-1">{{ $store.state.filterSideMenu.filterSideMenu.tambolSelect.name }}</span>
          </span>
        </span>
      </div>
    </template>
    <div
      class="py-4 px-5 dark:bg-gray-800 overflow-y-auto scrollbar-custom scrollbar-custom-light"
      style="height: calc(100vh - 135px)"
      :class="{
        block: $store.state.filterSideMenu.isOpenSidebar == true,
        hidden: $store.state.filterSideMenu.isOpenSidebar == false,
      }"
    >
      <div class="accordion" id="accordionExample5">
        <div class="text-white text-left py-4">
          <p class="font-semibold">{{ $store.state.sideMenuLabel.data_source }}</p>
          <div class="dropdown relative" data-te-dropdown-ref>
            <button
              class="dropdown-toggle w-full mt-2 px-4 py-2 bg-white text-dark-blue-custom font-semibold leading-snug rounded-lg shadow-md hover:bg-white hover:shadow-lg focus:bg-white focus:shadow-lg focus:outline-none focus:ring-0 active:bg-white active:shadow-lg transition duration-150 ease-in-out flex items-center whitespace-nowrap"
              type="button"
              id="dropdownMenuLargeButton"
              data-te-dropdown-toggle-ref
              aria-expanded="false"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              {{ $store.getters["filterSideMenu/getSourceSelectName"] }}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-down ml-auto"
              >
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            </button>
            <ul
              class="dropdown-menu w-full min-w-max absolute hidden bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 m-0 bg-clip-padding border-none [&[data-te-dropdown-show]]:block"
              aria-labelledby="dropdownMenuLargeButton"
              data-te-dropdown-menu-ref
            >
              <template v-for="item in $store.state.filterSideMenu.filterSideMenu.sourceList" :key="item.id">
                <li v-if="item.status === 'ACTIVE'">
                  <a
                    class="dropdown-item text-sm font-normal whitespace-nowrap text-gray-700 py-2 px-4 block w-full bg-transparent hover:bg-gray-100"
                    @click="setSourceFilter(item)"
                    >{{ item.name }}</a
                  >
                </li>
              </template>
            </ul>
          </div>
        </div>
        <!-- มุมมองการแสดงผล -->
        <div class="accordion-item" id="heading-view">
          <h2 class="accordion-header mb-0" id="headingOne1">
            <button
              class="accordion-button relative flex items-center w-full py-4 px-0 text-lg font-semibold text-left rounded-0 border-b-2 transition"
              type="button"
              data-te-collapse-init
              data-te-target="#collapseOne1"
              aria-expanded="true"
              aria-controls="collapseOne1"
            >
              {{ $store.state.sideMenuLabel.display_view }}
            </button>
          </h2>
          <div
            id="collapseOne1"
            class="accordion-collapse show !visible"
            data-te-collapse-item
            data-te-collapse-show
            aria-labelledby="headingOne1"
            data-te-parent="#heading-view"
          >
            <div class="text-white text-left py-4">
              <p class="font-semibold">{{ $store.state.sideMenuLabel.display_view }}</p>
              <div class="py-2 text-dark-blue-custom grid grid-cols-2 gap-3">
                <!-- <router-link to="/"> -->
                <div class="relative">
                  <button class="btn w-full bg-white font-light display-btn" :class="{ active: displayView == 'MAP' }" @click="displayView = 'MAP'">
                    <img class="h-16 d-block mx-auto" src="@/assets/images/icon/fill-blue.png" alt="thailand-icon" />
                    แผนที่
                  </button>
                  <div
                    v-if="displayView == 'MAP'"
                    class="absolute -mt-3"
                    style="width: 1.6rem; right: 0; left: 0; margin-left: auto; margin-right: auto"
                  >
                    <div class="relative">
                      <div class="absolute bg-orange-custom h-5 w-5 rounded-full"></div>
                      <check-icon size="1x" class="absolute text-white" style="margin-left: 2.3px; margin-top: 2.5px"> </check-icon>
                    </div>
                  </div>
                </div>
                <!-- </router-link>
                                    <router-link to="/table"> -->
                <div class="relative">
                  <button
                    class="btn bg-white w-full h-full font-light display-btn"
                    :class="{ active: displayView == 'TABLE' }"
                    @click="displayView = 'TABLE'"
                  >
                    <img class="h-10 mb-3 mt-3 d-block mx-auto" src="@/assets/images/icon/cells.png" alt="thailand-icon" />
                    ตาราง
                  </button>
                  <div
                    v-if="displayView == 'TABLE'"
                    class="absolute -mt-3"
                    style="width: 1.5rem; right: 0; left: 0; margin-left: auto; margin-right: auto"
                  >
                    <div class="relative">
                      <div class="absolute bg-orange-custom h-5 w-5 rounded-full"></div>
                      <check-icon size="1x" class="absolute text-white" style="margin-left: 2.3px; margin-top: 2.5px"> </check-icon>
                    </div>
                  </div>
                </div>
                <!-- </router-link> -->
              </div>
              <template v-if="buyPricePermission || $store.state.projectData.permissions === 'PUBLIC'">
                <p class="font-semibold mt-2">{{ $store.state.sideMenuLabel.display_data }}</p>
                <div class="w-full mt-2">
                  <div class="grid grid-cols-2 shadow-md hover:shadow-lg focus:shadow-lg" role="group">
                    <button
                      type="button"
                      class="rounded-l-lg py-2.5 mr-0.5 btn-group-custom bg-white text-dark-blue-custom leading-tight uppercase hover:opacity-100 focus:opacity-100 focus:outline-none focus:ring-0 active:opacity-100 active:text-orange-custom transition duration-150 ease-in-out"
                      :class="{ active: dataView == 'PRODUCT_COUNT', 'rounded-l-lg': !buyPricePermission }"
                      @click="dataView = 'PRODUCT_COUNT'"
                    >
                      จำนวน
                    </button>
                    <button
                      type="button"
                      class="rounded-r-lg py-2.5 btn-group-custom bg-white text-dark-blue-custom leading-tight uppercase hover:opacity-100 focus:opacity-100 focus:outline-none focus:ring-0 active:opacity-100 active:text-orange-custom transition duration-150 ease-in-out"
                      :class="{ active: dataView == 'PRODUCT_PRICE' }"
                      @click="dataView = 'PRODUCT_PRICE'"
                    >
                      มูลค่าการจัดซื้อ
                    </button>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>

        <!-- กรองเวลา -->
        <div class="accordion-item" id="heading-time">
          <h2 class="accordion-header mb-0" id="headingOne2">
            <button
              class="accordion-button relative flex items-center w-full py-4 px-0 text-lg font-semibold text-left rounded-0 border-b-2 transition"
              type="button"
              data-te-collapse-init
              data-te-target="#collapseOne2"
              aria-expanded="true"
              aria-controls="collapseOne2"
            >
              {{ $store.state.sideMenuLabel.filter_time }}
            </button>
          </h2>
          <div
            id="collapseOne2"
            class="accordion-collapse show !visible"
            data-te-collapse-item
            data-te-collapse-show
            aria-labelledby="headingOne2"
            data-te-parent="#heading-time"
          >
            <div class="text-white text-left py-4">
              <p class="font-semibold">{{ $store.state.sideMenuLabel.filter_year_type }}</p>
              <div class="w-full mt-2">
                <div class="grid grid-cols-2 shadow-md hover:shadow-lg focus:shadow-lg" role="group">
                  <button
                    type="button"
                    class="rounded-l-lg py-2.5 mr-0.5 btn-group-custom bg-white text-dark-blue-custom leading-tight uppercase hover:opacity-100 focus:opacity-100 focus:outline-none focus:ring-0 active:opacity-100 active:text-orange-custom transition duration-150 ease-in-out"
                    :class="{ active: yearType == 'FISCAL' }"
                    @click="yearType = 'FISCAL'"
                  >
                    ปีงบประมาณ
                  </button>
                  <button
                    type="button"
                    class="rounded-r-lg py-2.5 btn-group-custom bg-white text-dark-blue-custom leading-tight uppercase hover:opacity-100 focus:opacity-100 focus:outline-none focus:ring-0 active:opacity-100 active:text-orange-custom transition duration-150 ease-in-out"
                    :class="{ active: yearType == 'CALENDAR' }"
                    @click="yearType = 'CALENDAR'"
                  >
                    ปีปฎิทิน
                  </button>
                </div>
              </div>
            </div>

            <div class="text-white text-left">
              <p class="font-semibold">{{ $store.state.sideMenuLabel.filter_year_purchase }}</p>
              <!-- {{ yearList }} -->
              <multiselect
                class="w-full"
                :modelValue="yearSelect"
                :options="yearList"
                :searchable="true"
                :multiple="true"
                :selectedLabel="' '"
                :selectLabel="' '"
                :deselectLabel="' '"
                :allow-empty="true"
                :show-labels="false"
                :clear-on-select="false"
                :close-on-select="false"
                placeholder="กรุณาเลือกปี"
                @select="handleYearSelectChange"
                @remove="handleYearSelectRemove"
              >
                <template #clear="props">
                  <div
                    class="multiselect__clear"
                    v-if="yearSelect.length > 0 && yearSelect[0] !== 'ทั้งหมด'"
                    @mousedown.prevent.stop="clearAllYear(props.search)"
                  ></div>
                </template>
                <template #tag="{ option, remove }">
                  <MultiselectTag :option="option" :remove="remove" />
                </template>
                <template #noResult> ไม่พบข้อมูล </template>
              </multiselect>
            </div>
          </div>
        </div>

        <!-- ระดับการแสดงผล -->
        <div class="accordion-item" id="heading-level">
          <h2 class="accordion-header mb-0" id="headingOne3">
            <button
              class="accordion-button relative flex items-center w-full py-4 px-0 text-lg font-semibold text-left rounded-0 border-b-2 transition"
              type="button"
              data-te-collapse-init
              data-te-target="#collapseOne3"
              aria-expanded="true"
              aria-controls="collapseOne3"
            >
              {{ $store.state.sideMenuLabel.display_level }}
            </button>
          </h2>
          <div
            id="collapseOne3"
            class="accordion-collapse show !visible"
            data-te-collapse-item
            data-te-collapse-show
            aria-labelledby="headingOne3"
            data-te-parent="#heading-level"
          >
            <!-- working -->
            <template v-if="countryPermission || $store.state.projectData.permissions === 'PUBLIC'">
              <div class="text-white text-left pt-4">
                <p class="font-semibold">{{ $store.state.sideMenuLabel.display_level }}</p>
                <div class="w-full mt-2">
                  <div class="grid grid-cols-2 shadow-md hover:shadow-lg focus:shadow-lg" role="group">
                    <button
                      type="button"
                      class="rounded-l-lg py-2.5 mr-0.5 btn-group-custom bg-white text-dark-blue-custom leading-tight uppercase hover:opacity-100 focus:opacity-100 focus:outline-none focus:ring-0 active:opacity-100 active:text-orange-custom transition duration-150 ease-in-out"
                      :class="{ active: displayLevel == 'AREA' }"
                      @click="displayLevel = 'AREA'"
                    >
                      ระดับเขต
                    </button>
                    <button
                      type="button"
                      class="rounded-r-lg py-2.5 btn-group-custom bg-white text-dark-blue-custom leading-tight uppercase hover:opacity-100 focus:opacity-100 focus:outline-none focus:ring-0 active:opacity-100 active:text-orange-custom transition duration-150 ease-in-out"
                      :class="{ active: displayLevel == 'PROVINCE' }"
                      @click="displayLevel = 'PROVINCE'"
                    >
                      ระดับจังหวัด
                    </button>
                  </div>
                </div>
              </div>
            </template>

            <div class="text-white text-left mt-4">
              <div v-show="displayLevel == 'AREA'">
                <p class="font-semibold">{{ $store.state.sideMenuLabel.filter_health_area }}</p>
                <multiselect
                  class="w-full mt-1"
                  v-model="healthArea"
                  :options="healthAreaList"
                  :searchable="true"
                  :multiple="false"
                  :selectedLabel="' '"
                  :selectLabel="' '"
                  :show-labels="false"
                  :deselectLabel="' '"
                  placeholder="กรุณาเลือกเขตสุขภาพ"
                  :disabled="
                    displayLevel == 'PROVINCE' ||
                    (districtPermissionComputed && !!userComputed.HEALTH_AREA && $store.state.projectData.permissions !== 'PUBLIC')
                  "
                >
                  <template #singleLabel="value">
                    <div class="multiselect-single-label">
                      {{ value.option }}
                    </div>
                  </template>
                  <template #option="{ option }">
                    {{ option }}
                  </template>
                  <template #noResult> ไม่พบข้อมูล </template>
                </multiselect>
              </div>
              <p class="font-semibold" :class="{ 'mt-3': displayLevel == 'AREA' }">{{ $store.state.sideMenuLabel.filter_province }}</p>
              <multiselect
                class="w-full mt-1"
                v-model="provSelect"
                track-by="name"
                :key="provDropdownState"
                :options="provList"
                :searchable="true"
                :multiple="false"
                :selectedLabel="' '"
                :selectLabel="' '"
                :deselectLabel="' '"
                :show-labels="false"
                placeholder="กรุณาเลือกจังหวัด"
                :disabled="
                  (displayLevel == 'AREA' && healthArea == null) ||
                  (provincePermissionComputed && !!userComputed.PROVINCE_CODE && $store.state.projectData.permissions !== 'PUBLIC') ||
                  loadProv
                "
                label="name"
              >
                <template #option="{ option }">
                  {{ option.name }}
                </template>
                <template #clear="props">
                  <div
                    class="multiselect__clear"
                    v-if="provSelect != [] && provSelect && provSelect != null"
                    @mousedown.prevent.stop="clearAllProv(props.search)"
                  ></div>
                </template>
                <template #noResult> ไม่พบข้อมูล </template>
              </multiselect>
              <!-- {{ provSelect }}
                  <p>rstsrt</p>
                  {{ provList }} -->

              <p class="font-semibold mt-3">{{ $store.state.sideMenuLabel.filter_amphur }}</p>
              <multiselect
                class="w-full mt-1"
                v-model="ampSelect"
                track-by="name"
                :options="ampList"
                :searchable="true"
                :multiple="false"
                :selectedLabel="' '"
                :selectLabel="' '"
                :deselectLabel="' '"
                :show-labels="false"
                placeholder="กรุณาเลือกอำเภอ"
                :disabled="(displayLevel == 'AREA' && healthArea == null) || provSelect == null"
                label="name"
              >
                <template #option="{ option }">
                  {{ option.name }}
                </template>
                <template #clear="props">
                  <div class="multiselect__clear" v-if="ampSelect" @mousedown.prevent.stop="clearAllAmp(props.search)"></div>
                </template>
                <template #noResult> ไม่พบข้อมูล </template>
              </multiselect>

              <p class="font-semibold mt-3">{{ $store.state.sideMenuLabel.filter_tambol }}</p>
              <multiselect
                class="w-full mt-1"
                v-model="tambolSelect"
                track-by="name"
                :options="tambolList"
                :searchable="true"
                :multiple="false"
                :selectedLabel="' '"
                :selectLabel="' '"
                :deselectLabel="' '"
                :show-labels="false"
                placeholder="กรุณาเลือกตำบล"
                :disabled="(displayLevel == 'AREA' && healthArea == null) || ampSelect == null"
                label="name"
              >
                <template #option="{ option }">
                  {{ option.name }}
                </template>
                <template #clear="props">
                  <div class="multiselect__clear" v-if="tambolSelect" @mousedown.prevent.stop="clearAllTambol(props.search)"></div>
                </template>
                <template #noResult> ไม่พบข้อมูล </template>
              </multiselect>
            </div>
          </div>
        </div>

        <!-- พัสดุ/ครุภัณฑ์ -->
        <div class="accordion-item" id="heading-item">
          <h2 class="accordion-header mb-0" id="headingOne4">
            <button
              class="accordion-button relative flex items-center w-full py-4 px-0 text-lg font-semibold text-left rounded-0 border-b-2 transition"
              type="button"
              data-te-collapse-init
              data-te-target="#collapseOne4"
              aria-expanded="true"
              aria-controls="collapseOne4"
            >
              {{ $store.state.sideMenuLabel.filter_materials_or_equipment }}
            </button>
          </h2>
          <div
            id="collapseOne4"
            class="accordion-collapse show !visible"
            data-te-collapse-item
            data-te-collapse-show
            aria-labelledby="headingOne4"
            data-te-parent="#heading-item"
          >
            <div class="text-white text-left">
              <p class="font-semibold mt-3">{{ $store.state.sideMenuLabel.filter_materials_supplies }}</p>
              <MultiselectPagination
                class="w-full mt-1"
                id="equipment-select"
                v-model="equipmentSelect"
                :options="equipmentList"
                :searchable="true"
                :internal-search="false"
                :multiple="true"
                :selectedLabel="' '"
                :selectLabel="' '"
                :deselectLabel="' '"
                :show-labels="false"
                placeholder="กรุณาเลือกพัสดุจัดหา"
                :loading="loadEquipment"
                @fetch-option="handelFetchEquipmentList"
                @Search-change="handelSearchEquipment"
              >
                <template #singlelabel="{ value }">
                  <div class="multiselect-single-label">
                    {{ value }}
                  </div>
                </template>
                <template #clear="props">
                  <div class="multiselect__clear" v-if="equipmentSelect" @mousedown.prevent.stop="clearAllEquipment(props.search)"></div>
                </template>
                <template #tag="{ option, remove }">
                  <MultiselectTag :option="option" :remove="remove" />
                </template>
                <template #noResult> ไม่พบข้อมูล </template>
              </MultiselectPagination>
              <!-- <multiselect
                    class="w-full mt-1"
                    v-model="equipmentSelect"
                    :options="equipmentList"
                    :searchable="true"
                    :multiple="false"
                    :selectedLabel="' '"
                    :selectLabel="' '"
                    :deselectLabel="' '"
                    placeholder="กรุณาเลือกพัสดุจัดหา"
                  >
                    <template #singlelabel="{ value }">
                      <div class="multiselect-single-label">
                        {{ value }}
                      </div>
                    </template>
                    <template #option="{ option }">
                      {{ option }}
                    </template>
                    <template #clear="props">
                      <div
                        class="multiselect__clear"
                        v-if="equipmentSelect"
                        @mousedown.prevent.stop="clearAllEquipment(props.search)"
                      ></div>
                    </template>
                    <template #noResult> ไม่พบข้อมูล </template>
                  </multiselect> -->

              <p class="font-semibold mt-3">
                {{ $store.state.sideMenuLabel.filter_tool_or_equipment_name }}
              </p>
              <!-- ชื่อเครื่องมือ/อุปกรณ์ -->
              <MultiselectPagination
                v-model="productSelect"
                id="product-select"
                class="w-full mt-1"
                :options="productList"
                :searchable="true"
                :internal-search="false"
                :multiple="true"
                selected-label=" "
                :select-label="' '"
                :deselect-label="' '"
                :show-labels="false"
                track-by="PRODUCT_NAME"
                label="PRODUCT_NAME"
                placeholder="กรุณาเลือกเครื่องมือ/อุปกรณ์"
                @fetch-option="handelFetchProductList"
                @Search-change="handelSearchProductChange"
                :loading="loadProduct"
              >
                <template #singlelabel="{ value }">
                  <div class="multiselect-single-label">
                    {{ value }}
                  </div>
                </template>
                <template #clear="props">
                  <div v-if="productSelect.length > 0" class="multiselect__clear" @mousedown.prevent.stop="clearAllProduct(props.search)" />
                </template>
                <template #option="props">
                  <div>
                    <p>
                      {{ props.option.PRODUCT_NAME }}
                    </p>
                    <p class="text-gray-400 eng-text">
                      {{ props.option.PRODUCT_NAME_ENG }}
                    </p>
                  </div>
                </template>
                <template #tag="{ option, remove }">
                  <MultiselectTag :option="option.PRODUCT_NAME" :remove="remove" />
                </template>
                <template #noResult> ไม่พบข้อมูล </template>
              </MultiselectPagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="text-white text-left py-4 px-5"
      :class="{
        flex: $store.state.filterSideMenu.isOpenSidebar == true,
        hidden: $store.state.filterSideMenu.isOpenSidebar == false,
      }"
    >
      <p class="underline underline-offset-2 text-gray-custom cursor-pointer" @click="clearFilterSideMenu()">ล้างค่า</p>
      <button
        class="btn ml-auto float-right flex rounded-xl"
        :class="{
          'bg-orange-custom': !$store.state.mapData.mapLoading,
          'bg-gray-custom cursor-not-allowed': $store.state.mapData.mapLoading,
        }"
        id="display_btn"
        @click="displayClick()"
        :disabled="$store.state.mapData.mapLoading"
      >
        <font-awesome-icon :icon="['fas', 'fa-spinner']" class="mr-2 mt-1 animate-spin" v-show="$store.state.mapData.mapLoading" />
        <eye-icon size="1x" class="mr-2 mt-1" v-show="!$store.state.mapData.mapLoading"></eye-icon>
        แสดงผล
      </button>
    </div>
  </SidebarLayout>
</template>

<script>
import Multiselect from "vue-multiselect";

import { EyeIcon, CheckIcon } from "@zhuowenli/vue-feather-icons";
import MultiselectTag from "../base/multiselect/MultiselectTag.vue";
import MultiselectPagination from "../base/multiselect/MultiselectPagination.vue";

import SidebarLayout from "./SidebarLayout.vue";

import $ from "jquery";

import Mixins from "@/Mixins";
import axios from "axios";

import { useStore } from "@/store";
import { useAuth } from "@/hooks/useAuth";

const store = useStore();
const { showBuyPricePermission, countryPermission, provincePermission, districtPermission, getUser } = useAuth();

export default {
  components: {
    EyeIcon,
    CheckIcon,
    Multiselect,
    MultiselectTag,
    MultiselectPagination,
    SidebarLayout,
  },
  mixins: [Mixins],
  data() {
    return {
      // มุมมองการแสดงผล
      displayView: store.state.filterSideMenu.filterSideMenu.displayView,
      // มุมมองการแสดงผล
      dataView: store.state.filterSideMenu.filterSideMenu.dataView,
      // รูปแบบปี
      yearType: store.state.filterSideMenu.filterSideMenu.yearType,
      loadYear: true,
      // ตัวแปรปีที่จัดซื้อ
      // yearSelect: store.state.filterSideMenu.filterSideMenu.yearSelect,
      yearSelect: [],
      yearList: [],
      // ระดับการแสดงผล
      displayLevel: store.state.filterSideMenu.filterSideMenu.displayLevel,
      healthArea: store.state.filterSideMenu.filterSideMenu.healthArea,
      healthAreaList: store.state.filterSideMenu.filterSideMenu.healthAreaList,
      // ตัวแปรจังหวัด
      loadProv: true,
      provDropdownState: 0,
      provSelect: store.state.filterSideMenu.filterSideMenu.provinceSelect,
      provList: [],
      // ตัวแปรอำเภอ
      loadAmp: true,
      ampSelect: store.state.filterSideMenu.filterSideMenu.amphurSelect,
      ampList: [],
      // ตัวแปรตำบล
      loadTambol: true,
      tambolSelect: store.state.filterSideMenu.filterSideMenu.tambolSelect,
      tambolList: [],
      // ตัวแปรพัสดุจัดหา
      loadEquipment: true,
      equipmentSelect: store.state.filterSideMenu.filterSideMenu.equipmentType,
      equipmentList: ["mock 1", "mock 2", "mock 3"],
      equipmentListPage: 1,
      totalEquipmentList: 0,
      equipmentListSearch: "",
      equipmentListSearchTimeout: null,
      // ตัวแปรเครื่องมือ/อุปกรณ์ (วัสดุ/ครุภัณฑ์)
      loadProduct: true,
      productSelect: [],
      productList: [],
      productListPage: 1,
      totalProductList: 0,
      productListSearch: "",
      productListSearchTimeout: null,
      // filterCollectionName: {
      //   data: {
      //     data_source: "แหล่งข้อมูล",
      //   },
      //   display: {
      //     display_view: "มุมมองการแสดงผล",
      //     display_data: "ข้อมูลที่แสดงผล",
      //     display_level: "ระดับการแสดงผล",
      //   },
      //   filter: {
      //     filter_time: "กรองเวลา",
      //     filter_year_type: "รูปแบบปี",
      //     filter_year_purchase: "ปีที่จัดซื้อ",
      //     filter_province: "จังหวัด",
      //     filter_amphur: "อำเภอ",
      //     filter_tambol: "ตำบล",
      //     filter_materials_or_equipment: "พัสดุ/ครุภัณฑ์",
      //     filter_materials_supplies: "พัสดุจัดหา",
      //     filter_tool_or_equipment_name: "ชื่อเครื่องมือ/อุปกรณ์",
      //   },
      // },
    };
  },
  computed: {
    userComputed() {
      return getUser();
    },
    buyPricePermission() {
      const permission = showBuyPricePermission();
      // if (!permission) this.dataView = "PRODUCT_COUNT";
      return permission;
    },
    countryPermission() {
      const permission = countryPermission();
      return permission;
    },
    provincePermissionComputed() {
      const permission = provincePermission();
      // console.log("provincePermission", permission);
      // if (permission) this.provSelect = { name: getUser().PROVINCE_NAME, id: getUser().PROVINCE_CODE };
      return permission;
    },
    districtPermissionComputed() {
      const permission = districtPermission();
      return permission;
    },
  },
  methods: {
    handleYearSelectRemove: function (e) {
      this.yearSelect = this.yearSelect.filter((item) => item !== e);
    },
    handleYearSelectChange: function (e) {
      if (e === "ทั้งหมด") {
        this.yearSelect = [];
      } else {
        this.yearSelect = this.yearSelect.filter((item) => item !== "ทั้งหมด");
        this.yearSelect.push(e);
      }
    },
    hideSideMenu: function () {
      store.dispatch("filterSideMenu/hideSideMenuClick");
    },
    setSourceFilter: function (data) {
      store.commit("filterSideMenu/selectSource", data);
      // Mixins.methods.logNewData();
    },
    // Func - ดึงเลขปีจาก API
    initialYearList: function () {
      this.loadYear = true;

      // console.log('show yearSelect 1');
      // console.log(this.yearSelect);

      axios.get(`/api/dashboard/product/${store.getters["filterSideMenu/getSourceSelectURL"]}/year`).then((response) => {
        if (response.status === 200) {
          // Loop for year name array
          const yearList_Temp = response.data;
          yearList_Temp.sort(function (a, b) {
            return b - a;
          }); // เรียงเลขปีจากมากไปน้อย
          if (this.$store.state.filterSideMenu.filterSideMenu.yearSelect) {
            this.yearSelect = [...this.$store.state.filterSideMenu.filterSideMenu.yearSelect];
          } else {
            this.yearSelect = ["ทั้งหมด"];
          }
          // var yearSelect_temp = Math.max(...yearList_Temp);
          // this.yearSelect = []; // เซ็ตค่า Defualt Year Select เป็นปีมากสุดใน List
          // yearSelect_temp = yearSelect_temp.map(String);
          // this.yearSelect.push(yearSelect_temp.toString());
          // console.log('show yearSelect 2');
          // console.log(this.yearSelect);

          store.commit("filterSideMenu/setYearList", [...yearList_Temp]);
          yearList_Temp.unshift("ทั้งหมด");

          this.yearList = yearList_Temp;

          this.loadYear = false;
          return true;
        } else {
          // console.log(response.status.statusText);
          return false;
        }
      });
    },
    initialProvList: function () {
      this.loadProv = true;

      axios.get(`/api/map/refAdms`, {}).then((response) => {
        if (response.status === 200) {
          // Loop for new province name array
          var new_prov_list = [];
          for (let item of response.data) {
            new_prov_list.push({
              name: item.PROVINCE_NAME,
              id: item._id.PROVINCE_ID,
            });
            new_prov_list = new_prov_list.sort((a, b) => (a.name > b.name ? 1 : -1));
          }
          this.provList = new_prov_list;
          this.loadProv = false;
        }
        //  else {
        //   console.log(response.status.statusText);
        // }
      });
    },
    initialProvHealthList: function () {
      this.loadProv = true;
      var new_prov_list = [],
        new_product_map_health_list = [],
        health_area = 1;

      const productMapPromise = new Promise((resolve, reject) => {
        // console.log("have healthArea?");
        // console.log(this.healthArea);
        if (this.healthArea && this.healthArea != null) {
          health_area = this.healthArea;
          health_area = health_area.split(" ");
          health_area = health_area[1];
          // console.log("1.1 test : " + parseInt(health_area));
        }
        var params_product_map = {
          SELECT_YEAR: store.state.filterSideMenu.filterSideMenu.yearType,
          DISPLAY_LEVEL: this.displayLevel,
          HEALTH_AREA: parseInt(health_area),
          YEAR: store.getters["filterSideMenu/getYearSelect"],
          SORTERS: store.state.filterSideMenu.filterSideMenu.dataView,
        };
        // console.log("1.2 test : " + parseInt(health_area));

        axios.post(`/api/dashboard/product/${store.getters["filterSideMenu/getSourceSelectURL"]}/map`, params_product_map).then((response2) => {
          if (response2.status === 200) {
            if (response2.data && response2.data != null) {
              // console.log(response.data);
              new_product_map_health_list = [];
              for (let item of response2.data) {
                new_product_map_health_list.push(item.PROVINCE_NAME);
              }
              // console.log(new_product_map_health_list);
              store.commit("filterSideMenu/setProvinceList", new_product_map_health_list);
              // console.log(store.state.filterSideMenu.filterSideMenu.provinceList);
              resolve(new_product_map_health_list); // Yay! Everything went well!
            }
            //  else {
            //   console.log("Do nothing on you.");
            // }
          } else {
            reject("fail api load");
            // console.log(`error /api/dashboard/product/${store.getters["filterSideMenu/getSourceSelectURL"]}/map`);
          }
        });
        // }, 250);
      });

      productMapPromise
        .then((successMessage) => {
          axios.get(`/api/map/refAdms`).then((response) => {
            if (response.status === 200) {
              // Loop for new province name array
              new_product_map_health_list = successMessage;

              const productMapPromise2 = new Promise((resolve) => {
                // console.log(new_product_map_health_list);
                for (let item2 of new_product_map_health_list) {
                  for (let item of response.data) {
                    if (item2 == item.PROVINCE_NAME) {
                      new_prov_list.push({
                        name: item.PROVINCE_NAME,
                        id: item._id.PROVINCE_ID,
                      });
                      new_prov_list = new_prov_list.sort((a, b) => (a.name > b.name ? 1 : -1));
                    }
                  }
                }
                resolve(new_prov_list);
              });

              productMapPromise2.then((successMessage) => {
                this.provList = successMessage;
                this.provDropdownState++;
                this.loadProv = false;
              });
              // .catch((reject) => {
              //   if (reject.cause) {
              //     console.error("Had previously handled error");
              //   } else {
              //     console.error(`Trouble with promiseGetWord(): ${reject}`);
              //   }
              // });
            }
          });
        })
        .catch((reject) => {
          if (reject.cause) {
            console.error("Had previously handled error");
          } else {
            console.error(`Trouble with promiseGetWord(): ${reject}`);
          }
        });
    },
    initialAmpList: async function (prov_id) {
      this.loadAmp = true;

      await axios.get(`/api/map/refAdms?PROVINCE_ID=${prov_id}`, {}).then((response) => {
        if (response.status === 200) {
          // Loop for new amphur name array
          var new_amp_list = [];
          for (let item of response.data) {
            new_amp_list.push({
              name: item.AMPHUR_NAME,
              id: item._id.AMPHUR_ID,
            });
            new_amp_list = new_amp_list.sort((a, b) => (a.PROVINCE_NAME > b.PROVINCE_NAME ? 1 : -1));
          }
          this.ampList = new_amp_list;
          this.loadAmp = false;
        }
      });
    },
    initialTambolList: async function (amp_id) {
      this.loadTambol = true;

      await axios.get(`/api/map/refAdms?AMPHUR_ID=${amp_id}`, {}).then((response) => {
        if (response.status === 200) {
          // Loop for new tambol name array
          var new_tambol_list = [];
          for (let item of response.data) {
            new_tambol_list.push({
              name: item.TAMBOL_NAME,
              id: item._id.TAMBOL_ID,
            });
            new_tambol_list = new_tambol_list.sort((a, b) => (a.PROVINCE_NAME > b.PROVINCE_NAME ? 1 : -1));
          }
          this.tambolList = new_tambol_list;
          this.loadTambol = false;
        }
      });
    },
    handelFetchEquipmentList() {
      if (this.loadEquipment || this.totalEquipmentList < 10 * this.equipmentListPage) return;
      this.equipmentListPage += 1;
      this.initialEquipmentList();
    },
    handelSearchEquipment(searchQuery) {
      if (this.equipmentListSearchTimeout) clearTimeout(this.equipmentListSearchTimeout);
      this.equipmentListSearchTimeout = setTimeout(() => {
        this.equipmentListPage = 1;
        this.equipmentListSearch = searchQuery;
        this.initialEquipmentList();
      }, 400);
    },
    initialEquipmentList: function () {
      this.loadEquipment = true;

      var params_equipment_name = {
        START: "1",
        LENGTH: 10 * this.equipmentListPage + "",
        DRAW: 10 * this.equipmentListPage + "",
        ...(this.equipmentListSearch ? { GOODS_NAME: this.equipmentListSearch } : {}),
      };
      axios.post(`/api/dashboard/goods/name`, params_equipment_name).then((response) => {
        if (response.status === 200) {
          // Loop for year name array
          this.equipmentList = response.data.data;
          this.totalEquipmentList = response.data.recordsTotal;

          // var
          this.loadEquipment = false;
        }
      });
    },
    async handelFetchProductList() {
      if (this.loadProduct || this.totalProductList < 10 * this.productListPage) return;
      this.productListPage += 1;
      return await this.initialProductList();
    },
    handelSearchProductChange(searchQuery) {
      if (this.productListSearchTimeout) clearTimeout(this.productListSearchTimeout);
      this.productListSearchTimeout = setTimeout(() => {
        this.productListPage = 1;
        this.productListSearch = searchQuery;
        this.initialProductList();
      }, 400);
    },
    async initialProductList() {
      this.loadProduct = true;
      const params_product_name = {
        START: "1",
        LENGTH: 10 * this.productListPage + "",
        DRAW: 10 * this.productListPage + "",
        ...(this.productListSearch ? { PRODUCT_NAME: this.productListSearch } : {}),
        EN: true,
      };
      const response = await axios
        .post(`/api/dashboard/product/${store.getters["filterSideMenu/getSourceSelectURL"]}/name`, params_product_name)
        .catch((err) => {
          return err.response;
        });
      if (response.status === 200) {
        // Loop for year name array
        this.productList = response.data.data;
        this.totalProductList = response.data.recordsTotal;

        this.productSelect = response.data.data.filter((item) => store.state.filterSideMenu.filterSideMenu.equipmentName.includes(item.PRODUCT_NAME));

        // var
        this.loadProduct = false;
      }
      return this.productList;
    },
    clearFilterSideMenu: function () {
      // const yearListPromise = new Promise((resolve, reject) => {
      //   if (this.initialYearList() == true) {
      //     resolve('success')
      //   } else {
      //     reject('fail');
      //   }
      // });

      // yearListPromise.then(
      //   function (value) {
      //     if (value == 'success') {// มุมมองการแสดงผล
      this.displayView = "MAP";
      // มุมมองการแสดงผล
      this.dataView = "PRODUCT_COUNT";
      // รูปแบบปี
      this.yearType = "CALENDAR";
      this.loadYear = true;
      // ตัวแปรปีที่จัดซื้อ
      // yearSelect = store.state.filterSideMenu.filterSideMenu.yearSelect;
      // console.log(store.state.filterSideMenu.filterSideMenu.yearList);
      this.yearSelect = ["ทั้งหมด"];
      // this.yearList = store.state.filterSideMenu.filterSideMenu.yearList;
      // ระดับการแสดงผล
      this.displayLevel = "PROVINCE";
      this.healthArea = null;
      this.healthAreaList = store.state.filterSideMenu.filterSideMenu.healthAreaList;
      // ตัวแปรจังหวัด
      this.loadProv = true;
      this.provDropdownState = 0;
      this.provSelect = null;
      this.initialProvList;
      // ตัวแปรอำเภอ
      this.loadAmp = true;
      this.ampSelect = null;
      this.ampList = [];
      // ตัวแปรตำบล
      this.loadTambol = true;
      this.tambolSelect = null;
      this.tambolList = [];
      // ตัวแปรพัสดุจัดหา
      this.equipmentSelect = null;
      this.equipmentList = ["mock 1", "mock 2", "mock 3"];
      // ตัวแปรเครื่องมือ/อุปกรณ์ (วัสดุ/ครุภัณฑ์)
      this.productSelect = [];
      this.initialEquipmentList();
      this.initialProductList();
      //     } else if (value == 'fail') { this.clearFilterSideMenu(); }
      //   }
      // )
    },
    setSidebarOpen: function () {
      // var width = $(window).width();
      // // var height = $(window).height();
      // if (width >= 1199) {
      //   store.commit("filterSideMenu/setIsOpenSidebar", true);
      // } else {
      //   store.commit("filterSideMenu/setIsOpenSidebar", false);
      // }
    },
    displayClick: function () {
      var vm = this;

      // console.log('get in this');
      // Check year select change value

      store.commit("filterSideMenu/setDataView", vm.dataView);
      store.commit("filterSideMenu/setYearType", vm.yearType);
      store.dispatch("filterSideMenu/setYearSelectAction", vm.yearSelect);

      store.commit("filterSideMenu/setDisplayLevelSelect", vm.displayLevel);
      store.dispatch("filterSideMenu/setHealthAreaAction", vm.healthArea);
      store.commit("filterSideMenu/setProvSelect", vm.provSelect);
      store.commit("filterSideMenu/setAmphurSelect", vm.ampSelect);
      store.commit("filterSideMenu/setTambolSelect", vm.tambolSelect);

      store.commit("filterSideMenu/setEquipmentTypeSelect", vm.equipmentSelect);
      const productSelectMap = vm.productSelect.map((item) => item.PRODUCT_NAME);
      store.commit("filterSideMenu/setEquipmentNameSelect", productSelectMap);

      // >>> Set mapShow var for refresh
      store.commit("mapData/setMapShow", false);

      // Trigger ไปหน้าตารางให้อัปเเดตตารางด้วย
      store.commit("tableData/setTableKeyUpdate");
      store.commit("filterSideMenu/setDetailCardUpdateKey");
      // console.log('detailCardUpdateKey');
      // console.log(store.state.filterSideMenu.filterSideMenu.detailCardUpdateKey);

      if (vm.displayView != store.state.filterSideMenu.filterSideMenu.displayView) {
        store.commit("filterSideMenu/setDisplayView", vm.displayView);
        if (store.state.filterSideMenu.filterSideMenu.displayView == "MAP") {
          vm.$router.push({ name: "map", replace: true });
        } else {
          vm.$router.push({ name: "table", replace: true });
        }
      }

      // set store State
      // Mixins.methods.setStateToLocalStorage();

      // console.log("All store filterSideMenu !! (On click display btn)");
      // Mixins.methods.logNewData();

      // console.log(store.state.filterSideMenu.filterSideMenu);
    },
    clearAllYear: function () {
      // set default year
      this.yearSelect = [this.yearList[0]];
    },
    clearAllProv: function () {
      this.provSelect = null;
    },
    clearAllAmp: function () {
      this.ampSelect = null;
    },
    clearAllTambol: function () {
      this.tambolSelect = null;
    },
    clearAllEquipment: function () {
      this.equipmentSelect = null;
    },
    clearAllProduct: function () {
      this.productSelect = [];
    },
    async getFilterCollectionName() {
      const response = await fetch("/filter_collection.json");
      const file = await response.json();
      if (file) this.filterCollectionName = file;
    },
  },
  mounted() {
    store.dispatch("filterSideMenu/setLoadingFilter", true);
    if (this.$store.state.projectData.permissions !== "PUBLIC") {
      if (this.provincePermissionComputed) {
        if (this.userComputed.PROVINCE_NAME) {
          this.provSelect = { name: this.userComputed.PROVINCE_NAME, id: this.userComputed.PROVINCE_CODE };
          store.dispatch("filterSideMenu/setProvSelect", this.provSelect);
        }
      }
      if (this.districtPermissionComputed) {
        this.displayLevel = "AREA";
        if (this.userComputed.HEALTH_AREA) {
          this.healthArea = this.userComputed.HEALTH_AREA;
          store.dispatch("filterSideMenu/setHealthAreaSelect", this.userComputed.HEALTH_AREA);
        }
        store.dispatch("filterSideMenu/setDisplayLevelSelect", "AREA");
      }
    }
    this.getFilterCollectionName();

    this.setSidebarOpen();
    var vm = this;
    // var vm = this;
    $(window).resize(function () {
      vm.setSidebarOpen();
    });

    this.initialProvList();
    this.initialYearList();
    this.initialEquipmentList();
    this.initialProductList();

    if (this.healthArea != null) {
      var health_area = this.healthArea;
      health_area = health_area.split(" ");
      health_area = health_area[1];
      this.initialProvHealthList(health_area);
    }

    if (this.provSelect !== null) {
      this.initialAmpList(this.provSelect.id);
    }
    if (this.ampSelect !== null) {
      this.initialTambolList(this.ampSelect.id);
    }

    // console.log("All store filterSideMenu !! (On mount page)");
    // console.log(store.state.filterSideMenu.filterSideMenu);
    store.dispatch("filterSideMenu/setLoadingFilter", false);
  },
  watch: {
    healthArea(newHealthArea, oldHealthArea) {
      // console.log("new & old HealthArea");
      // console.log(newHealthArea);
      // console.log(oldHealthArea);
      if (newHealthArea != oldHealthArea) {
        this.provSelect = null;
        this.ampSelect = null;
        this.tambolSelect = null;
      }
      if (newHealthArea != null) {
        // console.log("-------> healthArea on change");
        // console.log(newHealthArea);
        var health_area = newHealthArea;
        health_area = health_area.split(" ");
        health_area = health_area[1];
        this.initialProvHealthList(health_area);
      }
    },
    provSelect(newprovSelect) {
      this.ampSelect = null;
      this.tambolSelect = null;
      // console.log('newprovSelect');
      // console.log(newprovSelect);
      if (newprovSelect !== null) {
        this.initialAmpList(newprovSelect.id);
        store.commit("filterSideMenu/setProvSelectTemp", newprovSelect);
      } else {
        store.commit("filterSideMenu/setProvSelectTemp", []);
      }
    },
    ampSelect(newAmpSelect) {
      this.tambolSelect = null;
      if (newAmpSelect !== null) {
        this.initialTambolList(newAmpSelect.id);
      }
    },
    yearSelect(newYearSelect) {
      // console.log(newYearSelect);
      if (newYearSelect.length == 0) {
        this.yearSelect = [this.yearList[0]];
      }
      // else {
      //   console.log(newYearSelect);
      // }
    },
    displayLevel: function (newVal, oldVal) {
      if (newVal != oldVal) {
        if (this.$store.state.projectData.permissions !== "PUBLIC") {
          if (this.districtPermissionComputed && this.userComputed.HEALTH_AREA) this.healthArea = this.userComputed.HEALTH_AREA;
          else this.healthArea = null;
          if (this.provincePermissionComputed && this.userComputed.PROVINCE_NAME) {
            this.provSelect = { id: this.userComputed.PROVINCE_CODE, name: this.userComputed.PROVINCE_NAME };
          } else this.provSelect = null;
        } else {
          this.healthArea = null;
          this.provSelect = null;
        }
        this.ampSelect = null;
        this.tambolSelect = null;
        if (newVal == "PROVINCE" && oldVal == "AREA") {
          this.initialProvList();
        }
      }
    },
    "$store.state.filterSideMenu.filterSideMenu.displayView"() {
      if (this.$store.state.filterSideMenu.filterSideMenu.displayView !== this.displayView)
        this.displayView = this.$store.state.filterSideMenu.filterSideMenu.displayView;
    },
    "$store.state.filterSideMenu.filterSideMenu.equipmentName"(newVal) {
      // console.log("$store.state.filterSideMenu.filterSideMenu.equipmentName");
      this.productSelect = this.productList.filter((item) => newVal.includes(item.PRODUCT_NAME));
    },
  },
};
</script>

<style scoped lang="scss">
.display-btn {
  border: 3px solid transparent;
  opacity: 0.5;
}

.display-btn:hover {
  opacity: 1;
}

.display-btn.active {
  opacity: 1;
  border: 3px solid var(--orange-color);
}

.btn-group-custom {
  opacity: 0.5;
  color: var(--dark-blue-color);
}

.btn-group-custom:hover {
  opacity: 1;
}

.btn-group-custom.active {
  opacity: 1;
  color: var(--orange-color);
}

.collapse {
  visibility: visible;
}

.multiselect__element .multiselect__option {
  white-space: nowrap !important;
  max-width: calc(100% - 100px) !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.multiselect__clear {
  position: absolute;
  right: 34px;
  height: 30px;
  width: 30px;
  display: block;
  cursor: pointer;
  z-index: 2;
}

.multiselect__clear:after,
.multiselect__clear:before {
  content: "";
  display: block;
  position: absolute;
  width: 3px;
  height: 12px;
  background: #aaa;
  top: 12px;
  right: 4px;
  cursor: pointer;
}

.multiselect__clear:before {
  transform: rotate(45deg);
}

.multiselect__clear:after {
  transform: rotate(-45deg);
}

.side-menu-list-custom {
  color: var(--orange-color);
  transition: all 0.3s ease-in-out;
}

.side-menu-list-custom:hover {
  background-color: rgba(255, 255, 255, 0.5);
  color: var(--dark-blue-color);
}

.side-menu-list-custom.active {
  background-color: rgba(255, 255, 255, 0.9);
  color: var(--dark-blue-color);
}
.multiselect__tags {
  padding: 8px 55px 0 8px;
}
.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 2px !important;
  top: -1px !important;
  bottom: 0;
  font-weight: 700;
  font-style: initial;
  width: 26px;
  text-align: center;
  line-height: 20px !important;
  transition: all 0.2s ease;
  border-radius: 5px;
}

.display-level-capsule-container {
  width: max-content;
  &.active {
    @media screen and (max-width: 1600px) {
      max-width: 40vw;
    }
    @media screen and (max-width: 1240px) {
      max-width: 40vw;
    }
    @media screen and (max-width: 1050px) {
      max-width: 35vw;
    }
    @media screen and (max-width: 1023px) {
      max-width: 60vw;
    }
    @media screen and (max-width: 840px) {
      max-width: 50vw;
    }
    @media screen and (max-width: 670px) {
      max-width: 40vw;
    }
    @media screen and (max-width: 560px) {
      display: none;
    }
  }
  @media screen and (max-width: 1300px) {
    max-width: 50vw;
  }
  // @media screen and (max-width: 1050px) {
  //   max-width: 45vw;
  // }
  @media screen and (max-width: 1023px) {
    max-width: 45vw;
  }
  @media screen and (max-width: 840px) {
    max-width: 40vw;
  }
  @media screen and (max-width: 767px) {
    max-width: 90vw;
  }
  @media screen and (max-width: 400px) {
    max-width: 85vw;
  }
  .display-level-capsule {
    padding-top: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 999px;
    z-index: 99;
    width: max-content;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    span {
      display: flex;
      white-space: nowrap;
    }
  }
}
</style>
