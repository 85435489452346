<template>
  <span ref="tag" class="multiselect__tag" tabindex="0" :data-te-toggle="disabled ? 'tooltip' : ''" :title="disabled ? `ชุดข้อมูลปิดการใช้งาน` : ''">
    <span class="break-all whitespace-normal" :class="[disabled && 'text-gray-400']">{{
      props.label ? props.option[props.label] : props.option
    }}</span>
    <i
      tabindex="1"
      class="multiselect__tag-icon border-l border-l-gray-300 after:hidden flex justify-center rounded-none items-center text-gray-500"
      @click="handleRemove"
    >
      <XIcon />
    </i>
  </span>
</template>

<script setup>
import { onMounted, ref, onBeforeUnmount } from "vue";
import { Tooltip } from "tw-elements";
import XIcon from "@zhuowenli/vue-feather-icons/icons/XIcon";
const props = defineProps({
  option: [String, Object],
  remove: Function,
  label: String,
  trackBy: String,
  emitRemove: Boolean,
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(["remove"]);

const tag = ref(null);
const tooltip = ref(null);

const handleRemove = () => {
  if (props.emitRemove) {
    emits("remove");
    return;
  }
  props.remove(props.trackBy ? props.option[props.trackBy] : props.option);
};

onMounted(() => {
  tooltip.value = new Tooltip(tag.value);
});

onBeforeUnmount(() => {
  if (tooltip.value) {
    tooltip.value.hide();
  }
});
</script>
