<template>
  <button
    v-bind="attr"
    :class="[
      props.color === 'primary' && 'bg-orange-custom',
      props.color === 'secondary' && 'bg-dark-blue-custom',
      props.color === 'light' && 'bg-transparent text-black shadow-none',
      props.rounded && 'rounded-full',
      !props.rounded && 'rounded-lg',
    ]"
    class="btn float-right text-white transition relative hover:opacity-80 disabled:opacity-80"
    :disabled="props.loading"
  >
    <span class="font-light flex items-center">
      <font-awesome-icon :icon="['fas', 'fa-spinner']" class="mr-2 mt-1 animate-spin" v-if="props.loading" />
      <template v-else>
        <slot name="icon"> </slot>
      </template>
      <slot>button</slot>
    </span>
  </button>
</template>

<script setup>
import { useAttrs } from "vue";
const attr = useAttrs();
const props = defineProps({
  loading: Boolean,
  // color primary and secondary
  color: {
    type: String,
    default: "primary",
  },
  rounded: {
    type: Boolean,
    default: false,
  },
});
</script>
