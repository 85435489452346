const INIT_FILTER_VALUE = {
  mapShow: false,
  mapLoading: false,
  productList: [],
  productAmount: [],
  productListSelect: null,
  productAmountSelect: null,
  productListNonLocation: [],
  productNonLocationAmount: null,
};

const state = () => {
  return {
    ...INIT_FILTER_VALUE,
  };
};

// getters
const getters = {
  getMapShow: (state) => state.mapShow,
  getProductList: (state) => state.productList,
  getProductAmount: (state) => state.productAmount,
  getMapLoading: (state) => state.mapLoading,
  getProductSelect: (state) => state.productListSelect,
  getProductAmountSelect: (state) => state.productAmountSelect,
  getProductListNonLocation: (state) => state.productListNonLocation,
  getProductNonLocationAmount: (state) => state.productNonLocationAmount,
};

// actions
const actions = {
  setProductListNonLocation({ commit }, data) {
    commit("setProductListNonLocation", data);
  },
  setProductNonLocationAmount({ commit }, data) {
    commit("setProductNonLocationAmount", data);
  },
};

// mutations
const mutations = {
  clearStore(state) {
    state = {
      ...INIT_FILTER_VALUE,
    };
    Object.assign(state, state);
  },
  setMapShow(state, data) {
    state.mapShow = data;
  },
  setProductList(state, data) {
    state.productList = data;
  },
  setProductAmount(state, data) {
    state.productAmount = data;
  },
  setMapLoading(state, data) {
    state.mapLoading = data;
  },
  setProductListSelect(state, data) {
    state.productListSelect = data;
  },
  setProductAmountSelect(state, data) {
    state.productAmountSelect = data;
  },
  setProductListNonLocation(state, data) {
    if (data.length > 0) state.productListNonLocation = data;
    else state.productListNonLocation = [];
  },
  setProductNonLocationAmount(state, data) {
    state.productNonLocationAmount = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
