import { createStore } from "vuex";

// import VuexPersistence from "vuex-persist";

import main from "./main";
import filterSideMenu from "./modules/filter-side-menu";
import mapData from "./modules/map-data";
import tableData from "./modules/table-data";
import dataSource from "./modules/data-source";
import projectData from "./modules/project-data";
import userData from "./modules/user-data";
import sideMenuLabel from "./modules/side-menu-label";

// const vuexLocalStorage = new VuexPersistence({
//   key: "vuex", // The key to store the state on in the storage provider.
//   storage: window.localStorage, // or window.sessionStorage or localForage
//   reducer: (state) => ({ userData: state.userData }),
//   // Function that passes the state and returns the state with only the objects you want to store.
//   // reducer: state => state,
//   // Function that passes a mutation and lets you decide if it should update the state in localStorage.
//   // filter: mutation => (true)
// });

const store = createStore({
  modules: {
    main,
    filterSideMenu,
    mapData,
    tableData,
    dataSource,
    projectData,
    userData,
    sideMenuLabel,
  },
  // plugins: [vuexLocalStorage.plugin],
});

export function useStore() {
  return store;
}

export default store;
