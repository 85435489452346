import Swal from "sweetalert2";

const useSweetAlert = () => {
  return Swal.mixin({
    confirmButtonText: "ตกลง",
    cancelButtonText: "ยกเลิก",
    cancelButtonColor: "var(--gray-color)",
    confirmButtonColor: "var(--orange-color)",
    reverseButtons: true,
    iconColor: "var(--orange-color)",
  });
};

export { useSweetAlert };
