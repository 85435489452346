const INIT_FILTER_VALUE = {
  isOpenSidebar: true,
  filterSideMenu: {
    loadingFilter: true,
    loadingProject: true,
    sourceSelect: 1,
    sourceList: [
      {
        id: 1,
        name: "e-GP",
      },
    ],
    displayView: "",
    dataView: "PRODUCT_COUNT",
    yearType: "CALENDAR",
    yearSelect: ["ทั้งหมด"],
    yearList: [],
    displayLevel: "PROVINCE",
    healthArea: null,
    healthAreaList: [
      "เขตสุขภาพที่ 1",
      "เขตสุขภาพที่ 2",
      "เขตสุขภาพที่ 3",
      "เขตสุขภาพที่ 4",
      "เขตสุขภาพที่ 5",
      "เขตสุขภาพที่ 6",
      "เขตสุขภาพที่ 7",
      "เขตสุขภาพที่ 8",
      "เขตสุขภาพที่ 9",
      "เขตสุขภาพที่ 10",
      "เขตสุขภาพที่ 11",
      "เขตสุขภาพที่ 12",
      "เขตสุขภาพที่ 13",
    ],
    provinceSelect: null,
    provinceList: null,
    amphurSelect: null,
    tambolSelect: null,
    equipmentType: null,
    equipmentTypeList: [],
    equipmentName: [],
    equipmentNameList: [],

    provinceSelectTemp: null,

    detailCardUpdateKey: 0,
  },
};

const state = () => {
  return {
    ...INIT_FILTER_VALUE,
  };
};

// getters
const getters = {
  getIsOpenSidebar: (state) => state.isOpenSidebar,
  getFilterSourceList: (state) => state.sourceList,
  getSourceSelectName: (state) => {
    var selectSourceItem = state.filterSideMenu.sourceSelect;
    return selectSourceItem.name;
  },
  getSourceSelectURL: (state) => {
    var selectSourceItem = state.filterSideMenu.sourceSelect;
    return selectSourceItem.param_name;
  },
  getdataViewName: (state) => {
    var selectSourceItem = state.filterSideMenu.sourceList[state.filterSideMenu.sourceSelect - 1];
    return selectSourceItem.name;
  },
  getFilterSideMenu: (state) => {
    return state.filterSideMenu;
  },
  getYearSelect: (state) => {
    if (typeof state.filterSideMenu.yearSelect === "string") return [state.filterSideMenu.yearSelect];
    if (
      state.filterSideMenu.yearSelect.length === 0 ||
      (state.filterSideMenu.yearSelect.length === 1 && state.filterSideMenu.yearSelect[0] === "ทั้งหมด")
    )
      return [];
    return state.filterSideMenu.yearSelect.map((item) => parseInt(item));
  },
  getLoadingProject: (state) => state.filterSideMenu.loadingProject,
  getLoadingFilter: (state) => state.filterSideMenu.loadingFilter,
};

// actions
const actions = {
  clearStore({ commit }) {
    commit("clearStore");
  },
  setStore({ commit }, store) {
    commit("setStore", store);
  },
  hideSideMenuClick({ commit, state }) {
    commit("setIsOpenSidebar", !state.isOpenSidebar);
    // console.log(state.isOpenSidebar);
  },
  setYearSelectAction({ commit }, state) {
    if (state.length === 1 && state[0] === "ทั้งหมด") {
      commit("setYearSelect", state);
      return;
    }
    // Create an empty array of number
    var numberArray = [];
    // Store length of array of string
    var length = state.length;

    for (var i = 0; i < length; i++) {
      numberArray.push(parseInt(state[i]));
    }
    // Save value by mutation
    commit("setYearSelect", numberArray);
  },
  setHealthAreaAction({ commit }, state) {
    // Save value by mutation
    commit("setHealthAreaSelect", state);
  },
  setDisplayView({ commit }, data) {
    commit("setDisplayView", data);
  },
  setDisplayLevelSelect({ commit }, data) {
    commit("setDisplayLevelSelect", data);
  },
  setSourceList({ commit }, data) {
    commit("setSourceList", data);
  },
  selectSource({ commit }, data) {
    commit("selectSource", data);
  },
  setLoadingProject({ commit }, data) {
    commit("setLoadingProject", data);
  },
  setLoadingFilter({ commit }, data) {
    commit("setLoadingFilter", data);
  },
  setProvSelect({ commit }, data) {
    commit("setProvSelect", data);
  },
  setHealthAreaSelect({ commit }, data) {
    commit("setHealthAreaSelect", data);
  },
  setEquipmentNameSelect({ commit }, data) {
    commit("setEquipmentNameSelect", data);
  },
  setOpenSidebar({ commit }, boo) {
    commit("setOpenSidebar", boo);
  },
  setYearList({ commit }, arr) {
    commit("setYearList", arr);
  },
};

// mutations
const mutations = {
  clearStore(state) {
    var old_state = {
      ...INIT_FILTER_VALUE,
    };
    Object.assign(state, old_state);
  },
  setStore(state, data) {
    Object.assign(state, data);
  },
  setIsOpenSidebar(state, data) {
    state.isOpenSidebar = data;
  },
  setSourceSelect(state, data) {
    state.filterSideMenu.sourceSelect = data;
  },
  setDisplayView(state, data) {
    state.filterSideMenu.displayView = data;
  },
  setDataView(state, data) {
    state.filterSideMenu.dataView = data;
  },
  setYearType(state, data) {
    state.filterSideMenu.yearType = data;
  },
  setYearSelect(state, data) {
    state.filterSideMenu.yearSelect = data;
  },
  setYearList(state, data) {
    state.filterSideMenu.yearList = data;
  },
  setDisplayLevelSelect(state, data) {
    state.filterSideMenu.displayLevel = data;
  },
  setHealthAreaSelect(state, data) {
    state.filterSideMenu.healthArea = data;
  },
  setProvSelect(state, data) {
    state.filterSideMenu.provinceSelect = data;
    state.filterSideMenu.amphurSelect = null;
    state.filterSideMenu.tambolSelect = null;
  },
  setProvinceList(state, data) {
    state.filterSideMenu.provinceList = data;
  },
  setAmphurSelect(state, data) {
    state.filterSideMenu.amphurSelect = data;
    state.filterSideMenu.tambolSelect = null;
  },
  setTambolSelect(state, data) {
    state.filterSideMenu.tambolSelect = data;
  },
  setEquipmentTypeSelect(state, data) {
    state.filterSideMenu.equipmentType = data;
  },
  setEquipmentNameSelect(state, data) {
    state.filterSideMenu.equipmentName = data;
  },
  setDetailCardUpdateKey(state) {
    state.filterSideMenu.detailCardUpdateKey++;
  },
  setProvSelectTemp(state, data) {
    state.filterSideMenu.provinceSelectTemp = data;
  },
  setSourceList(state, data) {
    state.filterSideMenu.sourceList = data;
  },
  selectSource(state, data) {
    state.filterSideMenu.sourceSelect = data;
  },
  setLoadingProject(state, data) {
    state.filterSideMenu.loadingProject = data;
  },
  setLoadingFilter(state, data) {
    state.filterSideMenu.loadingFilter = data;
  },
  setOpenSidebar(state, data) {
    state.isOpenSidebar = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
