<template>
  <div>
    <div class="relative block lg:hidden left-0" style="min-width: 30px; z-index: 9 !important"></div>

    <div
      class="absolute lg:relative transition"
      style="z-index: 1038"
      :class="{
        'w-80': isSideBarOpen == true,
        'w-7': isSideBarOpen == false,
      }"
    >
      <aside
        :class="{
          'w-80': isSideBarOpen == true,
          'w-7': isSideBarOpen == false,
        }"
        class="mt-1 bg-dark-blue-custom h-full fixed pr-4"
        aria-label="Sidebar"
      >
        <div class="absolute mt-1 ml-3" style="z-index: 999">
          <div
            :class="{
              'ml-72': isSideBarOpen == true,
              '-ml-2': isSideBarOpen == false,
            }"
          >
            <span @click="hideSideMenu" class="absolute drop-shadow-md hover:drop-shadow-xl rounded-full bg-white p-1 mt-5 cursor-pointer">
              <template v-if="isSideBarOpen">
                <chevrons-left-icon size="2x" class=""></chevrons-left-icon>
              </template>
              <template v-else>
                <chevrons-right-icon size="2x" class="ml-0.5"></chevrons-right-icon>
              </template>
            </span>
            <slot name="before-body"></slot>
          </div>
        </div>
        <template v-if="deleyrender">
          <slot></slot>
        </template>
      </aside>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed, onUnmounted } from "vue";
import { ChevronsRightIcon, ChevronsLeftIcon } from "@zhuowenli/vue-feather-icons";
import { useStore } from "vuex";

// const route = useRoute();
const store = useStore();

const hideSideMenu = () => {
  store.dispatch("filterSideMenu/hideSideMenuClick");
};
const ipadWidth = 821;

const isSideBarOpen = computed(() => store.state.filterSideMenu.isOpenSidebar);
const state = ref(0);
const onResize = () => {
  if (window.innerWidth < ipadWidth) {
    if (state.value === 0) {
      state.value = 1;
      store.dispatch("filterSideMenu/setOpenSidebar", false);
    }
  } else {
    state.value = 0;
  }
};

const deleyrender = ref(false);
onMounted(() => {
  if (window.innerWidth < ipadWidth) state.value = 1;
  setTimeout(() => {
    deleyrender.value = true;
    if (window.innerWidth < ipadWidth) {
      store.dispatch("filterSideMenu/setOpenSidebar", false);
    }
  }, 200);
  deleyrender.value;
  window.addEventListener("resize", onResize);
});

onUnmounted(() => {
  window.removeEventListener("resize", onResize);
});
</script>
